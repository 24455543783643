import { useState, useEffect, useMemo, useCallback, useRef } from "react";

export function useCb(start = (cb) => { }, stop = (cb) => { }, compute = _data => 0) {
  const init = useMemo(() => compute(null), [compute]);
  const [value, setValue] = useState(init);
  const ref = useRef(value);

  const cb = useCallback((event) => {
    const newValue = compute(event);
    if (ref.current !== newValue) {
      ref.current = newValue;
      setValue(newValue);
    }
  }, [compute, ref, setValue]);


  useEffect(() => {
    start(cb);
    return () => stop(cb);
  }, [start, stop, cb]);

  return value;
}

export const useListener = (eventName, compute = _event => 0, eventHost = window) => useCb(
  useCallback(listener => eventHost.addEventListener(eventName, listener), [eventHost, eventName]),
  useCallback(listener => eventHost.removeEventListener(eventName, listener), [eventHost, eventName]),
  compute
);
