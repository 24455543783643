import { gql } from '@apollo/client'
const fragments = {
  paginatorInfo: gql`
    fragment PaginatorInfoFragment on PaginatorInfo {
      total
      lastPage
      currentPage
      perPage
      firstItem
      hasMorePages
      lastItem
    }
  `,
  transaction: gql`
    fragment TransactionFragment on Transaction{
      id
      amount
      process_date
      status {
        id
        constant_value
        slug
      }
    }
  `,
  deal: gql`
    fragment DealFragment on Deal {
      id
      closing_date
      amount
      prod_status {
        id
        slug
        constant_value
      }
      sale_status {
        id
        slug
        constant_value
      }
      customer_production {
        id
        customerable {
          ... on Address {
            ...AddressFragment
          }
        }
      }
    }
  `,
  address: gql`
    fragment AddressFragment on Address {
      id
      street_name
      country
      city
      postal_code
      civic_number
      latitude
      longitude
      nickname
      province
    }
  `,
  project: gql`
    fragment ProjectFragment on Item {
      id
      line_item {
        id
        name
        saleCatalogItem {
          id
        }
      }
      tasks {
        id
        title
        updated_at
        status {
          id
          constant_value
          slug
        }
      }
    }
  `,
  card: gql`
    fragment CardFragment on PaymentInformation {
      id
      position
      exp_month
      exp_year
      last_4_card_number
      pm_alias
    }
  `
}
export default fragments