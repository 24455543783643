import Modals from 'components/modals';
import withMenu from 'hoc/with-menu';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setPropertyToEdit } from 'store/properties/actions';
import { DELETE_ADDRESS, GET_ADDRESS, UPDATE_ADDRESS } from './queries';
import { addressToProperty, fromProperty, propertyToAddress } from 'utils/addressToProperty';
import PropertyForm from '../property-form';



const EditProperty = (props) => {
  const propertyId = props.match.params.id;
  const isEdit = false;

  const [deleting, setDeleting] = useState(false);

  //#region queries
  const { data: { address } = {}, loading } = useQuery(GET_ADDRESS, { variables: { addressId: propertyId } })
  const [updateAddress] = useMutation(UPDATE_ADDRESS, {
    onCompleted: () => {
      Modals.success(t('property.edited'))
    },
    onError: (e) => {
      Modals.error(t('error'))
    },
    optimisticResponse: (vars) => {
      return { ...propertyToAddress(propertyToEdit), ...vars }
    },
    update: (cache, { data: { updateAddress: updated } }) => {
      cache.modify({
        id: `Address:${updated.id}`,
        fields(_, { fieldName }) {
          return updated[fieldName]
        }
      });
    }
  })
  const [deleteAddress] = useMutation(DELETE_ADDRESS, {
    onCompleted: () => {
      setDeleting(false)
      Modals.success(t('property.deleted'))
    },
    onError: (e) => {
      setDeleting(false)
      Modals.error(t('error'))
    },
    optimisticResponse: () => {
      return { id: propertyId }
    },
    update: (cache) => {
      cache.modify({
        fields: {
          addresses(value = { data: [] }, { readField }) {
            return { ...value, data: value.data.filter(a => readField('id', a) !== propertyId) };
          }
        }
      });
    }
  })
  //#endregion


  //#region hooks
  const dispatch = useDispatch();
  const propertyToEdit = useSelector(state => state.properties.propertyToEdit);
  const addressRef = useRef(address)
  addressRef.current = address
  const [input, setInput] = useState({ address: null, fromMap: true })
  const { t } = useTranslation();

  useEffect(() => {
    console.log(loading, addressRef.current)
    if (!loading) {
      const property = addressToProperty(addressRef.current);
      console.log(property)
      dispatch(setPropertyToEdit(property));
      setInput({ address: fromProperty(property), fromMap: false })
      // onLatLngChange(property.latLng)
    }
  }, [dispatch, addressRef, loading])
  //#endregion

  //#region handlers
  const onSubmit = () => {
    updateAddress({ variables: propertyToAddress(propertyToEdit) })
    props.history.push('/properties')
  }

  const confirmDelete = () => {
    setDeleting(true)
    deleteAddress({ variables: { id: propertyId } })
    props.history.replace('/properties')
  };

  const delProperty = () => {
    Modals.deleting(
      t('property.delete'),
      t('property.deleteMsg'),
      confirmDelete,
    );
  }
  //#endregion

  return <PropertyForm isEdit={isEdit} delProperty={delProperty} deleting={deleting} onSubmit={onSubmit} input={input} setInput={setInput} />
}

export default withMenu(EditProperty);
