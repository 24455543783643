export const saleItemCatalogImages = {
  4: 'lawn',
  5: 'lawn',
  6: 'lawn',
  7: 'lawn',
  8: 'grass',
  9: 'insect',
  10: 'insect',
  11: 'service',
  12: 'service',
  13: 'aeration',
  14: 'price',
  15: 'amendment',
  16: 'seed',
  17: 'insect',
  18: 'worm',
  19: 'insect',
  20: 'insect',
  21: 'fertilization',
  22: 'price',
  23: 'lawn',
  24: 'fertilization',
  25: 'borer',
  26: 'grass',
  27: 'grass',
  28: 'lawn',
}
