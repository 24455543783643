import axios from 'axios';
import Modals from 'components/modals';
import {
  connectFirstLogin,
  deleteAccount,
  ensureKeycloakSync,
  isLoggable,
  keycloak, keycloakClient, keycloakLogout, startLoginWithProvider, unlinkProvider, updatePassword,
} from 'keycloak-init';
import _ from 'lodash';
import store from '../../store';
import i18n from '../../i18n';
import { SET_FIRST_LOGIN, SET_CRED, SET_LOADING, SET_TOKEN, SET_USER, SET_NEED_CRED, SET_CRED_LOADING } from './actionTypes';
import { parseToken } from 'keycloak-lib';
import { Modal } from 'antd';

export const setLogin = (value) => ({
  type: SET_LOADING,
  payload: value
})

/**
 * @param {import('./initialState').UserProfile} user 
 */
export const setUser = (user) => ({
  type: SET_USER,
  payload: user
})

/**
 * @param {import('./initialState').Token} token 
 */
export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token
})

export const setFirstLoginPending = (pending = false) => ({
  type: SET_FIRST_LOGIN,
  payload: pending
})

export const setNeedCredentials = (needCredentials = false) => ({
  type: SET_NEED_CRED,
  payload: needCredentials
})

export const setCredentials = (cred) => ({
  type: SET_CRED,
  payload: cred
})

export const setCredentialsPending = (pending) => ({
  type: SET_CRED_LOADING,
  payload: pending
})

export const loadUser = () => async dispatch => {
  const profile = (await keycloak.getUserProfile()).data
  dispatch(setUser(profile));
  dispatch(loadCredentials())
}

export const loadCredentials = () => (dispatch) => (async () => {
  const [cred, idps] = await Promise.all([keycloak.getCredentials(), keycloak.getLinkedAccounts()]);
  const brokers = idps.data.filter(idp => idp.providerName !== 'reflect' && idp.providerName !== 'pwd' && idp.connected);
  const tokens = (await Promise.all(brokers.map(idp => keycloak.getBrokerToken(idp.providerName)))).map(res => res.data);
  const credentials = Object.fromEntries(brokers.map(idp => [idp.providerName, idp]));
  const { google, facebook } = credentials;
  if (google) {
    console.log(tokens, brokers.indexOf(google), brokers, google)
    Object.assign(google, _.pick(parseToken(tokens[brokers.indexOf(google)].id_token), 'email', 'picture', 'name'))
  }
  if (facebook) {
    const headers = { Authorization: `Bearer ${tokens[brokers.indexOf(facebook)].access_token}` };
    Object.assign(facebook, _.pick((await axios.get('https://graph.facebook.com/me', { headers })).data, 'name', 'id'));
  }
  if (cred.data.some(x => x.type === 'password' && x.userCredentials?.length)) credentials.password = true;
  dispatch(setCredentials(credentials));
})().catch(e => {
  setCredentialsPending(false)
  throw e;
})

export const userUpdatePassword = (values) => async (dispatch) => {
  try {
    await updatePassword(values);
    Modals.success(i18n.t('profile.passwordChanged'));
    dispatch(loadCredentials());
  } catch (e) {
    Modals.error(i18n.t('credentials.wrong'));
  }
}

export const editUser = (patch) => async (dispatch, getState = store.getState) => {
  const fields = ['firstName', 'lastName', 'email'];
  await keycloak.updateUserAccount({ ..._.pick(patch, ...fields), username: patch.email, attributes: _.omit(patch, ...fields) })
  dispatch(setUser({ ...getState().user.user, ...patch }));
  // TODO Here we send the request to the backend server to update user data
  // TODO We also send an email verification request (server side)
  Modals.success(i18n.t('profile.edited'));
  keycloak.loadUserProfile();
}

export const linkWith = (broker) => async dispatch => {
  await ensureKeycloakSync()
  console.log('match', keycloakClient.tokenParsed?.sub, keycloak.tokenParsed?.sub)
  const url = `${window.location.origin}/authenticate.html?link=${broker}`;
  const handle = (event) => {
    if (!event.data?.startsWith?.(url)) return;
    window.removeEventListener('message', handle);
    dispatch(loadCredentials())
  };
  window.addEventListener('message', handle);
  window.open(
    keycloakClient.createLinkUrl({ redirectUri: url, broker }),
    'auth', 'toolbar=no,width=600,height=600');
}

export const unlink = (provider) => async dispatch => {
  await unlinkProvider(provider);
  dispatch(loadCredentials());
}

export const signout = () => async (dispatch) => {
  console.log('signout')
  keycloak.clearToken();
  dispatch(setToken(null))
  dispatch(setUser(null))
  localStorage.removeItem('kc-tokens')
};

export const signin = ({ email, password }) => async dispatch => {
  try {
    dispatch(setLogin(true));
    console.log('signin')
    await keycloak.loginWithPassword({ username: email, password: password, });
    dispatch(setToken(keycloak.tokenParsed))
    localStorage.setItem('kc-tokens', JSON.stringify([keycloak.token, keycloak.refreshToken]))
    ensureKeycloakSync()
    dispatch(loadUser())
  } catch (err) {
    Modals.error(i18n.t('credentials.wrong'));
  } finally {
    dispatch(setLogin(false));
  }
};

/**
 * handle login with invitation code
 * @param {string} code the invitation code
 * @param {(res: ()=>void)=>void} clickEvent a click event to enable popup
 */
export const firstSignin = (code, clickEvent) => async (dispatch) => {
  dispatch(setFirstLoginPending(true))
  dispatch(setNeedCredentials(true))
  await connectFirstLogin(code).then(async (token) => {
    dispatch(setCredentials({}))
    checkSso([token, null])(dispatch)
    dispatch(setFirstLoginPending(false))
    if (clickEvent) await new Promise(clickEvent)
    await ensureKeycloakSync()
    localStorage.removeItem('kc-code')
    keycloak.setToken(keycloakClient.token, keycloakClient.refreshToken, 0)
    localStorage.setItem('kc-tokens', JSON.stringify([keycloak.token, keycloak.refreshToken]))
  }, e => {
    const { data } = e.response ?? {};
    let error;
    if (data?.message === 'user has credentials') {
      const credentials = [...(data.password ? ['password'] : []), ...data.idps].map(
        cred => i18n.t(`credentials.ownCredential`, { credential: i18n.t(`credentials.${cred}`) }));
      error = i18n.t('credentials.userHasCredentials', {
        credentials: credentials.length < 2 ? credentials[0]
          : [credentials.slice(0, -1).join(i18n.t('innerOr')), credentials.slice(-1)[0]].join(i18n.t('or'))
      });
    } else {
      error = i18n.t('credentials.wrongToken');
    }
    Modal.error({ title: error, afterClose: () => localStorage.removeItem('kc-code') });
    dispatch(setFirstLoginPending(false))
  });
}

/**
 * @param {'facebook'|'google'} provider 
 */
export const signInWithProvider = (provider) => async (dispatch) => {
  dispatch(setLogin(true))
  await keycloakLogout()
  startLoginWithProvider(provider, 'window')
};


/**
 * Update redux auth state and keycloak adapter with received tokens
 * @param {string?} tokens an optional json encoded array of token, refresh token
 * @returns redux action
 */
export const checkSso = (tokens) => (dispatch) => {
  if ((tokens !== null) && keycloak.authenticated) return

  if (tokens) {
    const [token, refreshToken] = typeof tokens === 'string' ? JSON.parse(tokens) : tokens
    const parsed = token && parseToken(token)
    if (!parsed || !isLoggable(parsed)) {
      dispatch(signout())
      deleteAccount(token)
      Modals.error(i18n.t('credentials.wrongUser'));
      return
    }
    keycloak.setToken(token, refreshToken, refreshToken ? 0 : null)
    dispatch(setToken(keycloak.tokenParsed))
    dispatch(loadUser())
  } else dispatch(signout())
}
