const initialState = {
  methods: [
    {
      id: 'crc1',
      type: 'credit-card',
      cardId: '0123456789012345',
      code: '4126',
      expires: '12/20',
      name: 'MR. CARDHOLDER',
    },
    {
      id: 'm1',
      type: 'monthly',
      name: 'Philippe',
      address: '1547, Boulevard',
      transit: '13245',
      instit: '132',
      accountNb: '1234567',
    }
  ],
  chain: 1,
  nextId: 1,
};

export default initialState;