import { useQuery } from '@apollo/client';
import Spin from 'antd/lib/spin';
import Card from 'components/card';
import Tabbed from 'components/tabbed';
import withMenu from 'hoc/with-menu';
import { useUserName } from 'hook/useUserName';
import moment from 'moment';
import Content from 'pages/container/content';
import Toolbar from 'pages/container/toolbar';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dealToContract } from 'utils/dealToContract';
import { statusMap } from 'utils/transactionStatusMap';
import { GET_HOME_DATA } from './queries';
import './styles.scss';

// Ahmed don't forget to remove William
const Home = () => {
  const { firstName } = useUserName();
  const { t } = useTranslation();
  const history = useHistory();
  const { data: responseData, loading } = useQuery(GET_HOME_DATA, { fetchPolicy: 'cache-first' });
  const deal = responseData?.deals?.data?.[0];
  const transactions = responseData?.transactions?.data;
  const transaction = responseData?.transaction?.data?.[0];
  const contract = deal && dealToContract(deal);
  const gotoContracts = () => history.push('/contracts');
  const gotoBilling = () => history.push('/billing');
  return <>
    <Toolbar title={t('overview.title')} />
    <Content>
      <div className="home-page">
        <div className="header-image" style={{ backgroundImage: 'url(/img/welcome.jpg)' }}>
          <div className="block-container">
            <h1><Trans i18nKey="home.welcome">Welcome {{ user: firstName }}</Trans></h1>
            <h2>{t('home.welcomeSub')}</h2>
          </div>
        </div>
        <div className="block-container">
          <div className="home-main">
            <Tabbed btns={[t('contracts.title'), t('payments.title'), { text: t('faq.acro'), href: t('supportLink'), target: '_blank' }]} panels={[
              <Card img="/img/contracts.png" title={<>
                <div className="show-md">
                  {t('contracts.single')}
                  {loading ? '...' : <span className="number"># {deal?.id}</span>}
                </div>
                <div className="hide-md">
                  {t('contracts.title')}
                </div>
              </>}>
                <div className="home_panel home_contract">
                  {loading ? <Spin /> : contract ? <><div className="status">{t('contracts.status.' + contract.status)}</div>
                    <span className="number hide-md"># {deal.id}</span>
                    <div className="address">{contract.property?.address?.join(', ')}</div></> : t('none')}
                </div>
                <div className="card-btn"><span onClick={gotoContracts}>{t('seeMore')}</span></div>
              </Card>,
              <Card img="/img/payments.png" title={<>
                <div className="show-md">
                  {t('payments.single')}
                  <span className="number"># {transaction?.id}</span>
                </div>
                <div className="hide-md">
                  {t('payments.future')}
                </div>
              </>}>
                <div className="home_panel home_payment show-md">
                  {transaction ? <><div>
                    <div>{moment(transaction.process_date).format('ll')}</div>
                    <div className={{
                      waiting: 'danger',
                      done: 'success',
                      canceled: 'danger',
                      inProgress: 'warning',
                    }[statusMap[transaction?.status.slug]]}>{transaction.status.constant_value}</div>
                  </div>
                    <div>
                      <div><b>{transaction.amount} $</b></div>
                      <div>Carte *1234</div>
                    </div></> : t('none')}
                </div>
                <div className="home_panel home_payment home_array hide-md">
                  {loading ? <div><Spin /></div> : transactions?.length ? transactions.map(
                    item => <div key={item.id}><div>{moment(item.process_date).format('ll')}</div><div>{item.amount} $</div></div>
                  ) : t('none')}
                </div>
                <div className="card-btn"><span onClick={gotoBilling}>{t('seeMore')}</span></div>
              </Card>,
              <Card img="/img/faq.png" title={t('faq.full')} >
                <div className="home_panel home_faq">
                  <div className="home_item">{t('home.services.label')}</div>
                  <div className="home_item">{t('home.services.production')}</div>
                  <div className="home_item">{t('home.services.weed')}</div>
                  <div className="home_item">{t('home.services.lawn')}</div>
                  <div className="home_item">{t('home.services.payment')}</div>
                </div>
                <div className="card-btn"><a href={t('supportLink')}>{t('faq.acro')}</a></div>
              </Card>,
            ]} />
          </div>
        </div>
      </div>
    </Content>
  </>;
};

export default withMenu(Home);