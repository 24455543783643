const property1 = {
  id: 1,
  img: '/img/M1.png',
  name: 'My First House',
  address: 'Rue des Saules, Saint-Eustache, Québec J7P, Canada',
  latLng: {
    lat: 45.5620996,
    lng: -73.89328470000001
  }
};
const property2 = {
  id: 2,
  img: '/img/M2.png',
  name: 'My Second House',
  address: 'Saint-Eustache, Quebec J7P, Canada',
  latLng: {
    lat: 45.5725491,
    lng: -73.8758655
  }
};
const property3 = {
  id: 3,
  img: '/img/M3.png',
  name: 'My Third House',
  address: '48 Angus Glen Boulevard, Markham, ON, Canada',
  latLng: {
    lat: 43.8907983,
    lng: -79.3216356
  }
};
const property4 = {
  id: 4,
  img: '/img/M4.png',
  name: 'My Forth House',
  address: '476 Chemin des Draveurs, Saint-Mathieu-du-Parc, QC, Canada',
  latLng: {
    lat: 46.5810255,
    lng: -72.9323835
  }
};

const initialState = {
  properties: [property1, property2, property3, property4],
  propertyToEdit: {
    id: '',
    name: '',
    address: [''],
    city: '',
    civic_number: '',
    country: '',
    postal_code: '',
    province: '',
    street_name: '',
    latLng: {
      lat: 46.8138783, lng: -71.2079809
    }
  },
}

export default initialState;
