import _ from 'lodash'
import { addressToProperty } from './addressToProperty'

export const dealToContract = ({ id, sale_status = {}, closing_date: date, customer_production = [] }) => ({
  property: _.chain(customer_production).flatMap(({ customerable: c }) => c ? [c] : []).map(
    addressToProperty
  ).value()[0],
  status: {
    Pending: 'waiting', Signed: 'done', Cancel: 'canceled', Lost: 'canceled', HOLD: 'inProgress'
  }[sale_status.slug],
  id,
  date,
})