import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
} from '@apollo/client'
import { QueryClient } from 'react-query'
import { onError } from "@apollo/client/link/error"

import Modals from 'components/modals'
import { keycloak } from 'keycloak-init'
import { baseUrl, TENAND_ID } from './constants'
import { signout } from 'store/user/actions'
import store from 'store'

const authLink = new ApolloLink((operation, forward) => {


  if (keycloak.authenticated) {
    const headers = {
      Authorization: `Bearer ${keycloak.token}`,
      'x-tenant': TENAND_ID,
    }

    operation.setContext({
      headers,
    })
  }

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    if (networkError.statusCode === 401) {
      Modals.error('You have been somehow disconnected')
      store.dispatch(signout())
    }
    else {
      Modals.error('Network Error', networkError.message)
    }
  }
})

const link = ApolloLink.from([
  authLink,
  errorLink,
  new HttpLink({ uri: baseUrl + '/graphql' }),
])

const client = new ApolloClient({
  link,
  connectToDevTools: true,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: { fetchPolicy: 'cache-first' }
  }
})

// Allows for different types of caches.
export const queryClient = new QueryClient();

export default client;