import fragments from "fragments";
import gql from "graphql-tag";

export const GET_CARDS = gql`
  query PaymentInformation {
    paymentInformations(first: 999) {
      data {
        ...CardFragment
      }
    }
  }
  ${fragments.card}
`