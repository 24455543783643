import { FlagTwoTone } from '@ant-design/icons';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import { useWindowScroll } from 'hook/useScroll';
import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Nav from './Nav';
import './styles.scss';

const Header = ({ withHeaderMenu }) => {

  const { t, i18n } = useTranslation();
  const top = useWindowScroll(({ scrollY }) => scrollY === 0);
  const phone = "1 844 41";
  const setLang = useCallback(lng => i18n.changeLanguage(lng), [i18n]);
  const history = useHistory();
  const path = history.location.pathname;
  const home = path === '/';
  const lang = i18n.language;
  const gotoHome = (event) => {
    event.preventDefault();
    history.push('/');
  }

  const menu = useMemo(() => <Menu onClick={({ key: lang }) => setLang(lang)}>
    <Menu.Item key="fr" disabled={lang.startsWith('fr')}>
      <span className="link">{t('lang.fr')}</span>
    </Menu.Item>
    <Menu.Item key="en" disabled={lang.startsWith('en')}>
      <span className="link">{t('lang.en')}</span>
    </Menu.Item>
  </Menu>, [lang, setLang, t]);

  return <div className="page-header">
    <div className={'top-bar' + (top ? '' : ' fadeout')}>
      <div className="block-container">
        <a className="logo" href="/" onClick={gotoHome}><img alt="logo" src={t('layout.logo-src')} /></a>
        <div className={'place' + (home ? '' : ' hide-md')}><img alt="place" src="/img/icon-place.png" /><span><span><Trans i18nKey="layout.place"><span className="hide-lg">EVERYWHERE IN </span><b>QUÉBEC</b></Trans></span></span></div>
        <div className="flag"><Dropdown overlay={menu} trigger={['click']}><FlagTwoTone twoToneColor="#52c41a" fill='#08c' style={{ color: '#08c' }} /></Dropdown></div>
        <div className="hide-md"><img alt="time" src="/img/icon-time.png" /><Trans i18nKey="layout.time"><span className="hide-lg">Opening time: </span><b>MON - FRI</b><span className="divider" /><b>8h to 16h</b></Trans></div>
        <div className="hide-md"><img alt="tel" src="/img/icon-tel.png" /><Trans i18nKey="layout.tel"><span className="free hide-lg">No charge </span><b>{{ phone }}C-GREEN</b></Trans></div>
        <div className="hide-md price"><img alt="price" src="/img/consumer-price.png" /></div>
      </div>
    </div>
    <div className="top-menu"><div className="block-container">
      <div className="links">
        {withHeaderMenu ? <>
          <Nav path="/" name="home.title" exact />
          <Nav path="/properties" name="properties.title" />
          <Nav path="/contracts" name="contracts.title" />
          <Nav path="/billing" name="billing.title" />
          <Nav path="/profile" name="profile.title" />
        </> : <></>}
        {lang === 'en'
          ? <span className="link" onClick={() => setLang('fr')}>{t('lang.fr')}</span>
          : <span className="link" onClick={() => setLang('en')}>{t('lang.en')}</span>
        }
      </div>
      <div className="right-btns">
        <a href={t('website')} className="skiplink green-btn">{t('layout.site')}</a>
        <a href={t('supportLink')} className="skiplink blue-btn">{t('faq.acro')}</a>
      </div>
    </div></div>
  </div>;
};
export default Header;