
import { gql } from '@apollo/client'

import fragments from 'fragments'

export const GET_TRANSACTIONS = gql`
  query Transactions($page: Int!, $first: Int!) {
    transactions(page: $page, first: $first, where: { HAS: {
      relation: "deal", 
    }}) {
      data {
        ...TransactionFragment
      }
      paginatorInfo {
        ...PaginatorInfoFragment
      }
    }
  }
  ${fragments.paginatorInfo}
  ${fragments.transaction}
`;


