export const getAddress = ({
  city, civic_number, country, postal_code, street_name, province,
}) => [`${civic_number}, ${street_name}`, `${city}, ${province} (${country})`, postal_code];
export const addressToProperty = ({
  id, city, civic_number, country, postal_code, street_name, province, latitude, longitude, nickname, street_type,
}) => ({
  id,
  img: `/img/M${(id % 4) + 1}.png`,
  address: getAddress({ city, civic_number, country, postal_code, street_name, province }),
  city, civic_number, country, postal_code, street_name, province,
  name: nickname,
  street_type,
  latLng: {
    lat: +latitude,
    lng: +longitude
  }
})
export const propertyToAddress = ({
  id, city, civic_number, country, postal_code, street_name, province, name, street_type, 
  latLng: { lat: latitude, lng: longitude }
}) => ({
  id, city, civic_number, country, postal_code, street_name, province, nickname: name, latitude, longitude, street_type,
  __typename: 'Address'
})

/**
* @param {typeof import('../store/properties/initialState').default.propertyToEdit} p 
*/
export const fromProperty = (p) => {
 return `${p.civic_number} ${p.street_name}, ${p.city}, ${p.postal_code}, ${p.province}, ${p.country}`
}
