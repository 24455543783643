import { keycloak, keycloakReady } from 'keycloak-init';
import { useLayoutEffect } from 'react';



const Auth = () => {
  useLayoutEffect(() => {
    keycloakReady.then(() => {
      if (keycloak.token) {
        localStorage.setItem('kc-tokens', JSON.stringify([keycloak.token, keycloak.refreshToken]))
      }
      window.parent.postMessage(window.location.href, window.location.origin);
      window.close();
    })
  }, []);
  return 'closing...';
}
export default Auth;