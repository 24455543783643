import moment from 'moment';
import Status from 'pages/contracts/Status';
import Spin from 'antd/lib/spin';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import './styles.scss';
import 'styles/_paginator.scss';
import { useLazyQuery } from '@apollo/client';
import { GET_TRANSACTIONS } from './queries';
import { statusMap } from 'utils/transactionStatusMap';


const BillingHistory = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const limit = 10;
  const onPageChange = ({ selected }) => setPage(selected);
  const [getTransactions, {
    data: { transactions: { data: billingHistory = [], paginatorInfo: { lastPage } = {} } = {} } = {}, loading
  }] = useLazyQuery(GET_TRANSACTIONS, { variables: { first: limit, page: 1 } });

  useEffect(() => {
    getTransactions({
      variables: { page: page }
    })
  }, [page, getTransactions])

  return <div className="block-container">
    <div className="history">
      <h3 className="group">{t('payments.title')}</h3>
      {loading ? <Spin /> : !billingHistory?.length ? t('none') : <><table>
        <tbody>
          {billingHistory.map(((item, i) => <tr key={i}>
            <td>{moment(item.process_date).format('ll')}</td>
            <td><strong>{item.amount} $</strong></td>
            <td><Status status={statusMap[item.status.slug]} label={item.status.constant_value} inline /></td>
          </tr>))}
        </tbody>
      </table>
        <div className="paginator">
          <ReactPaginate
            pageCount={lastPage}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={onPageChange}
            previousLabel={<i className="fas fa-arrow-left" />}
            nextLabel={<i className="fas fa-arrow-right" />} />
        </div>
        <div></div></>}
    </div>
  </div>;
}

export default BillingHistory;