import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkSso, signout } from 'store/user/actions';
import { keycloak, keycloakClient, keycloakReady } from '../keycloak-init';

export const useKeycloak = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // retreive initial stored tokens
    let lastTokens = localStorage['kc-tokens'];

    keycloakReady.then(() => {

      // request token refresh each time its expired (with minValidity of 30s) 
      keycloak.onTokenExpired = () => {
        console.log('token expired', keycloak.tokenParsed)
        keycloak.updateToken(30).then(() => {
          console.log('successfully get a new token', keycloak.tokenParsed)
          localStorage.setItem('kc-tokens', JSON.stringify([keycloak.token, keycloak.refreshToken]))
        }, (err) => {
          console.error('unable to refresh', err)
        })
      }

      // when token is expired and app or network was not active
      // for long time to refresh it, user is automatically disconnected
      keycloak.onAuthRefreshError = () => dispatch(signout())


      // update app state with initial retrieved tokens
      dispatch(checkSso(lastTokens));

      // if sync-auth is in query that means need to synchonize keycloak with keycloakClient
      const params = new URLSearchParams(window.location.search);
      if (params.has('sync-auth')) {
        params.delete('sync-auth')
        const { history, document, location } = window
        const search = params.toString()
        history.replaceState(history.state, document.title, location.pathname + (search ? '?' + search : ''))
        const action = () => {
          const newTokens = JSON.stringify([keycloakClient.token, keycloakClient.refreshToken])
          if (newTokens !== lastTokens) {
            dispatch(checkSso(lastTokens = newTokens))
          }
        }
        if (keycloakClient.token) action()
        else {
          keycloakClient.onAuthSuccess = () => {
            keycloakClient.onAuthSuccess = null
            action()
          }
        }
      }

    })

    // watch localstorage for change and synchronize app with its changes
    const watchStorageForSso = () => {
      keycloakReady.then(() => {
        const newTokens = localStorage['kc-tokens']
        if (lastTokens !== newTokens) dispatch(checkSso((lastTokens = newTokens) ?? null))
      })
    }

    // Register and unregister storager watcher for sso
    window.addEventListener('storage', watchStorageForSso);
    return () => window.removeEventListener('storage', watchStorageForSso);

  }, [dispatch]);
}
