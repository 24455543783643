import { gql } from '@apollo/client'

import fragments from 'fragments'

export const GET_ADDRESSES = gql`
  query Address {
    addresses(first: 9999) {
      data {
        ...AddressFragment
      }
    }
  }
  ${fragments.address}
`;

