
import { noop } from "lodash"


/**
 * run some client side action and redirect to a notifier which should send message 
 * to its parent starting by its url
 * 
 * @param {string} action some name for the action
 * @param {(url: string)=>string} createActionUrl make the action url using the redirectUrl
 * @param {string} redirectUrl the url of the notifier
 * @param {'iframe'|'popup'} [target] the target window
 * @returns void
 */

export const executeInIframe = (
  action, createActionUrl, redirectUrl = `${window.location.origin}/notify-parent.html?${action}`, target = 'iframe'
) => new Promise(res => {

  const src = createActionUrl(redirectUrl)
  let remove = noop

  const handle = (event) => {

    if (!event.data?.startsWith?.(redirectUrl)) return

    window.removeEventListener('message', handle)
    remove()
    res(event.data)

  }
  window.addEventListener('message', handle);

  if (target === 'iframe') {
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = src;
    remove = () => iframe.remove()
    document.body.appendChild(iframe)
  } else {
    window.open(src, action, 'toolbar=no,width=600,height=600')
  }
})
