import { useQuery } from '@apollo/client';
import Carousel from 'antd/lib/carousel';
import Spin from 'antd/lib/spin';
import Card from 'components/card';
import Modals from 'components/modals';
import Tabbed from 'components/tabbed';
import { useResize } from 'hook/useSize';
import { useWindowWidth } from 'hook/useWidth';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deletePaymentMethod, setPaymentMethod } from 'store/payments/actions';
import { GET_CARDS } from './queries';
import AutoDebit from './AutoDebit';
import CreditCard from './CreditCard';

import './styles.scss';
import 'styles/_carousel.scss';
import { useUserName } from 'hook/useUserName';

const getId = ((id = 1) => () => id++)();

const NewCard = ({ img, add, parent }) => {
  const { t } = useTranslation();
  const onlyBtns = useResize(parent, size => size?.contentRect.height < 128);

  return <div className="new_card">
    {img}
    <div>
      {onlyBtns ? null : <span className="success">{t('card.add')}</span>}
      <div className={onlyBtns ? 'only_btns' : ''}>
        <span className="blue-btn" onClick={() => add({ type: 'credit-card', id: getId() })}>{t('card.label')}</span>
        <span className="blue-btn" onClick={() => add({ type: 'monthly', id: getId() })}>{t('card.debit')}</span>
      </div>
    </div>
  </div>;
}
const PaymentHeader = ({ card, add, rm, isEdit }) => {
  const [ref, setRef] = useState(null);
  const img = <img alt="" src={card?.type === 'credit-card' ? '/img/card/credit.png' : '/img/card/specimen.png'} />;
  return <div>
    <div className="card_container" ref={setRef}>
      {card
        ? <>{img}{isEdit ? <span className="remove_card" onClick={() => rm(card)}>-</span> : null}</>
        : <NewCard {...{ img, add }} parent={ref} />
      }
    </div>
  </div>;
}


const PaymentModes = ({ isEdit }) => {

  //#region hooks
  const medium = useWindowWidth(width => width <= 700);
  const [selected, setSelectedIdx] = useState(0);
  const [addedMethods, setAddedMethods] = useState([]);
  const { data: { paymentInformations: { data: modes = [] } = {} } = {}, loading } = useQuery(GET_CARDS, { fetchPolicy: 'cache-first' });
  const { lastName } = useUserName();
  const modeToPayment = ({ id, exp_month, exp_year, last_4_card_number: digits, pm_alias }) => (pm_alias === 'card' ? {
    id,
    type: 'credit-card',
    cardId: `•••• •••• •••• ${digits}`,
    code: '••••',
    expires: `${exp_month}/${exp_year.slice(-2)}`,
    name: `MR. ${lastName}`,
  } : {
    id,
    type: 'monthly',
    name: `MR. ${lastName}`,
    address: '',
    transit: '•••••',
    instit: '•••',
    accountNb: '•••••••',
  });
  const methods = modes.map(modeToPayment);
  console.log(methods, loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { chain } = useSelector(state => state.payments);
  const allMethods = useMemo(() => methods.concat(addedMethods, isEdit ? [null] : []), [addedMethods, methods, isEdit]);
  const singlePanel = allMethods.length > 4;
  const oldRef = useRef({ selected: allMethods[selected], allMethods });
  const carouselRef = useRef(null);
  const setSelected = useCallback((selected) => {
    oldRef.current.selected = allMethods[selected];
    setSelectedIdx(selected);
  }, [oldRef, allMethods, setSelectedIdx]);
  const goTo = useCallback((selected, instantly) => {
    (medium || singlePanel) && carouselRef.current ? carouselRef.current.goTo(selected, instantly) : setSelected(selected);
  }, [setSelected, carouselRef, medium, singlePanel]);
  //#endregion

  //#region handlers
  const addCard = useCallback(card => {
    setAddedMethods([...addedMethods, card]);
    goTo(allMethods.length - 1);
  }, [addedMethods, setAddedMethods, goTo, allMethods]);
  const selectedMethod = allMethods[selected];
  const findIndexById = useCallback((list, item) => list.findIndex(m => m?.id === item?.id), []);
  useLayoutEffect(() => {
    const { allMethods: oldAllMethods, selected: oldSelected } = oldRef.current;
    oldRef.current = { allMethods, selected: selectedMethod };
    // if a change in the list causes the card in the selected position to no longer correspond to the selected one
    if (oldSelected?.id !== selectedMethod?.id && oldSelected !== null) {
      let idx = findIndexById(allMethods, oldSelected);
      const oldIdx = findIndexById(oldAllMethods, oldSelected);
      if (idx === -1) oldAllMethods.slice(oldIdx, -1).find(m => (idx = findIndexById(allMethods, m)) !== -1);
      if (idx === -1) {
        oldAllMethods.slice(0, oldIdx).reverse().find(m => (idx = findIndexById(allMethods, m)) !== -1);
        if (idx !== -1 && idx + 1 < allMethods.length - 1) idx = idx + 1;
      }
      setSelected(idx === -1 ? 0 : idx);
      goTo(idx === -1 ? 0 : idx, true);
    }
  }, [oldRef, allMethods, selectedMethod, goTo, setSelected, findIndexById]);
  const rmCard = useCallback(card => {
    if (typeof card.id === 'string') {
      Modals.deleting(t('card.delete'), t('card.deleteMsg'), () => dispatch(deletePaymentMethod(card.id)));
    } else {
      setAddedMethods(addedMethods.filter(x => x !== card));
    }
  }, [dispatch, t, addedMethods]);
  //#endregion

  //#region view
  const header = useMemo(() => allMethods.map((card) => {
    return <PaymentHeader key={card ? card.id : -1} card={card} add={addCard} rm={rmCard} edit={isEdit} />;
  }), [allMethods, addCard, rmCard, isEdit]);
  //#endregion

  return loading ? <Spin /> : <div className="payment_modes">
    {medium || singlePanel ? <div className="carousel_container">
      <div className="carousel">
        <Carousel draggable ref={carouselRef} speed={200} afterChange={setSelected} initialSlide={selected} >
          {header}
        </Carousel>
      </div>
    </div> : <div className="block-container payment_cards">{header}</div>}
    <div className="block-container">
      {allMethods?.length ? <Tabbed
        selected={selected}
        btns={allMethods.map(() => '')}
        singlePanel={singlePanel}
        panels={allMethods.map((card, pos) => {
          const Comp = card?.type === 'monthly' ? AutoDebit : CreditCard;
          const isNew = typeof card?.id !== 'string';
          return card ?
            [card.id, <div className="tabbed-card-container">
              <div className="show-md goto_plus" onClick={() => goTo(allMethods.length - 1)}>{t('card.add')}</div>
              <Card title={card.type === 'monthly' ? t('card.auto') : t('card.name.title')}>
                <Comp pos={pos} card={card} isEdit={isEdit} afterAdd={() => rmCard(card)}>{!isEdit || isNew
                  ? null
                  : pos <= Math.min(0, chain)
                    ? <div className="card-btn selected">
                      <span>{t('card.setDefault')}</span>
                    </div>
                    : pos <= Math.min(1, chain)
                      ? <div className="card-btn">
                        <span onClick={() => dispatch(setPaymentMethod(card.id, 0))}>{t('card.setDefault')}</span>
                      </div>
                      : <div className="card-btn">
                        <span onClick={() => dispatch(setPaymentMethod(card.id, 1))}>{t('card.setBackup')}</span>
                      </div>}
                </Comp>
              </Card>
            </div>]
            : [-1, <div className="empty_card" />];
        })} /> : t('none')}
    </div>
  </div>;
}

export default PaymentModes;