import { useState } from "react";
import './styles.scss';

const Tabbed = ({ btns, panels, isShown, onSelect, selected, singlePanel }) => {
  const [stateSelected, setSelected] = useState(0);
  const current = selected ?? stateSelected;
  const onClick = i => {
    if (selected === undefined) setSelected(i);
    onSelect?.(i);
  }
  return <div className="tabbed">
    <div className={'btns' + (isShown ? ' shown' : '')}>{
      btns.map((btn, i) => {
        return btn.href
          ? <a href={btn.href} target={btn.target} key={i} onClick={btn.action}>{btn.text}</a>
          : <div onClick={btn.action ?? (() => onClick(i))} key={i} className={current === i ? 'selected' : ''}>{btn.text ?? btn}</div>
      })
    }</div>
    <div className={"panels" + (singlePanel ? ' single_panel' : '')}>{
      panels.map((panel, i) => {
        const [key, elem] = Array.isArray(panel) ? panel : [i, panel];
        return <div key={key} className={current === i ? 'selected' : ''}>{elem}</div>;
      })
    }</div>
  </div>;
}
export default Tabbed;