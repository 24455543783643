import { gql } from '@apollo/client'

import fragments from 'fragments'

export const GET_DEALS = gql`
  query Deals {
    deals(first: 9999) {
      data {
        ...DealFragment
      }
      paginatorInfo {
        total
        lastPage
        currentPage
        perPage
        firstItem
        hasMorePages
        lastItem
      }
    }
  }
  ${fragments.deal}
  ${fragments.address}
`;


export const GET_DEAL_DETAILS = gql`
 query Projects($dealId: Mixed!) {
    projects(
      first: 9999,
      where: {
        HAS: {
          relation: "lineItem", 
          condition: { column: DEAL_ID, value: $dealId }
        } OR: { HAS: {
          relation: "deal", 
          condition: {column: ID, value: $dealId}
        }}
      }
    ) {
      data {
        ... ProjectFragment
      }
    }
  }
  ${fragments.project}
`;
