import React, { useMemo } from 'react'
import {
  BarChartOutlined,
  ProfileOutlined,
  UserOutlined,
  DollarOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import './style.scss';
import { useTranslation } from 'react-i18next';

const MenuButton = (props) => {
  const { title, icon, onClick, selected, href } = props;

  const className = useMemo(() => {
    return selected ? 'bottom_bar_menu_button bottom_bar_menu_button_selected' : 'bottom_bar_menu_button';
  },
    [selected])
  const Item = href ? 'a' : 'div';
  return (
    <Item {...href ? { href, target: '_blank' } : {}} onClick={onClick} className={className}>
      {icon()}
      <span>{title}</span>
    </Item>
  )
}

const BottomBarMenu = (props) => {

  const { t } = useTranslation();

  const { location: { pathname }, history: { push } } = props;

  return (
    <div className='bottom_bar_menu'>

      <MenuButton
        title={t('overview.title')}
        selected={pathname === '/'}
        onClick={() => { push('/') }}
        icon={() => <BarChartOutlined />} />

      <MenuButton
        title={t('properties.title')}
        selected={pathname.startsWith('/properties')}
        onClick={() => { push('/properties') }}
        icon={() => <EnvironmentOutlined />} />

      <MenuButton
        title={t('contracts.title')}
        selected={pathname.startsWith('/contracts')}
        onClick={() => { push('/contracts') }}
        icon={() => <UserOutlined />} />

      <MenuButton
        title={t('payments.title')}
        selected={pathname.startsWith('/billing')}
        onClick={() => { push('/billing') }}
        icon={() => <DollarOutlined />} />

      <MenuButton
        title={t('profile.title')}
        selected={pathname === '/profile'}
        onClick={() => { push('/profile') }}
        icon={() => <ProfileOutlined />}
      />

    </div>);
}

MenuButton.propTypes = {
  title: PropTypes.string,
  selected: PropTypes.bool,
  icon: PropTypes.func,
  onClick: PropTypes.func
}

export default withRouter(BottomBarMenu);
