import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const Nav = React.memo(({ path, name, selected, exact, paths }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const nav = useCallback(e => { e.preventDefault(); history.push(path) }, [history, path]);
  const url = history.location.pathname;
  const getIsSelected = p => {
    if (p === undefined) p = path;
    return exact ? url === p : url.startsWith(p);
  }
  const isSelected = selected ?? [...(paths ?? [path])].some(getIsSelected);
  return <a className={isSelected ? 'selected' : ''} href={path} onClick={nav}>{t(name)}</a>
});

export default Nav;