import Breadcrumbs from 'components/breadcrumbs';
import Tabbed from 'components/tabbed';
import withMenu from 'hoc/with-menu';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Spin from 'antd/lib/spin';

import Content from 'pages/container/content';
import Toolbar from 'pages/container/toolbar';
import Status from './Status';
import { GET_DEALS } from './queries'

import 'styles/_common_page.scss';
import './styles.scss';
import { dealToContract } from 'utils/dealToContract';

const CardGroupHeader = ({ group, items, filter }) => {
  if (filter === 'status') return <Status status={group} />;
  const text = filter === 'season' ? group : items[0].property?.address[0];
  return <div className="group">{text}</div>
}

const CardGroup = ({ group, items, filter }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return <>
    <CardGroupHeader {...{ group, items, filter }} />
    {items.map(item => <div key={item.id} className="contract_item">
      <img alt="" src={item.property?.img} />
      <div>
        <div>{filter === 'property' ? <Status status={item.status} inline /> : item.property?.address[0]}</div>
        <div style={{ whiteSpace: 'pre' }}>{item.property?.address.slice(1).join('\n')}</div>
        <div className="contract_menu">
          <div>{filter === 'season' ? <Status status={item.status} inline /> : item.date ? moment(item.date).format('ll') : t('contracts.status.waiting')}</div>
          <div># {item.id}</div>
          <div><div className="card-btn" onClick={() => history.push('/contracts/' + item.id)}><span>{t('details')}</span></div></div>
        </div>
      </div>
    </div>)}
  </>;
}


const Contracts = () => {

  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);
  const { data: { deals: { data: deals = [] } = {} } = {}, loading } = useQuery(GET_DEALS)

  const filters = ['season', 'property', 'status'];
  const btns = filters.map(key => t({ property: 'property.title' }[key] ?? key));
  const filter = filters[selected];
  const selectors = { season: contract => contract.date?.slice(0, 4) ?? t('contracts.status.waiting'), property: 'property.id', status: 'status' };

  const contracts = deals.map(dealToContract);
  const groups = _.chain(contracts).groupBy(selectors[filter]).map(
    (items, group) => ({ items, group })).orderBy('group', 'desc').value();

  return <>
    <Toolbar title={t('contracts.title')} />
    <Content>
      <div className="contracts_page common_page">
        <div className="header-image" style={{ backgroundImage: 'url(/img/contracts_header.png)' }}>
          <div className="block-container">
            <h1 className="hide-md">{t('contracts.title')}</h1>
          </div>
        </div>
        <Breadcrumbs page="contracts.title" />
        <div className="block-container">
          <div className="small-container">
            <div className="empty_space hide-md" />
            <Tabbed btns={btns} panels={[]} isShown onSelect={setSelected} selected={selected} />
            {loading ? <Spin /> : groups.map(({ items, group }) => <CardGroup group={group} items={items} key={group} filter={filter} />)}
          </div>
        </div>
      </div>
    </Content>
  </>;
}

export default withMenu(Contracts);
