import { useQuery } from '@apollo/client';
import Spin from 'antd/lib/spin';
import Breadcrumbs from 'components/breadcrumbs';
import withMenu from 'hoc/with-menu';
import Content from 'pages/container/content';
import Toolbar from 'pages/container/toolbar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { addressToProperty } from 'utils/addressToProperty';
import { GET_ADDRESSES } from './queries';
import './styles.scss';

const Properties = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: { addresses: { data: addresses = [] } = {} } = {}, loading } = useQuery(GET_ADDRESSES, {
    fetchPolicy: 'cache-first'
  })
  const properties = addresses.map(addressToProperty);
  const isEdit = false;

  const goToAddProperty = () => history.push('./properties/add')

  const title = t('properties.title');
  return <>
    <Toolbar title={title} />
    <Content>
      <div className="properties_page common_page">
        <div className="header-image" style={{ backgroundImage: 'url(/img/profile_header.png)' }}>
          <div className="block-container">
            <h1 className="hide-md">{title}</h1>
          </div>
        </div>
        <Breadcrumbs page={title} />
        <div className="block-container">
          <div className="empty_space" />
          <div className="small-container">
            {isEdit ? <div className='properties_btn_area'>
              <button className="blue-btn" style={{ minWidth: 100 }} onClick={goToAddProperty}>{t('properties.add')}</button>
            </div> : null}
            {loading ? <Spin /> : properties.map(property => {
              const address = property.address;
              return <div key={property.id} className="contract_item">
                <img alt="" src={property.img} />
                <div>
                  <div>{address[0]}</div>
                  <div style={{ whiteSpace: 'pre' }}>{address.slice(1).join('\n')}</div>
                  <div className="contract_menu">
                    <div># {property.id}</div>
                    <div><div className="card-btn" onClick={() => history.push('/properties/edit/' + property.id)}><span>{t(isEdit?'edit':'details')}</span></div></div>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    </Content>
  </>;
}

export default withMenu(Properties);
