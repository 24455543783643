import { gql } from '@apollo/client'

import fragments from 'fragments'

export const GET_ADDRESS = gql`
  query Address($addressId: ID!) {
    address(id: $addressId) {
      ...AddressFragment
    }
  }
  ${fragments.address}
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress(
    $id: ID!,
    $street_name: String!,
    $country: String!,
    $city: String!,
    $postal_code: String!,
    $civic_number: String!,
    $province: String!,
    $latitude: Float,
    $longitude: Float,
    $nickname: String,
  ) {
    updateAddress(input: {
      id: $id,
      street_name: $street_name,
      province: $province,
      country: $country,
      city: $city,
      postal_code: $postal_code,
      civic_number: $civic_number,
      latitude: $latitude,
      longitude: $longitude,
      nickname: $nickname
    }){id, ...AddressFragment}
  }
  ${fragments.address}
`;

export const DELETE_ADDRESS = gql`
  mutation UpdateAddress(
    $id: ID!,
  ) {
    deleteAddress(id: $id){id}
  }
`;