import Checkbox from 'antd/lib/checkbox';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Switch from 'antd/lib/switch';
import Breadcrumbs from 'components/breadcrumbs';
import Card from 'components/card';
import Modals from 'components/modals';
import Tabbed from 'components/tabbed';
import withMenu from 'hoc/with-menu';
import { noop } from 'lodash';
import Content from 'pages/container/content';
import Toolbar from 'pages/container/toolbar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { editUser, linkWith, signout, unlink, userUpdatePassword } from 'store/user/actions';
import 'styles/_common_page.scss';
import { emailRE } from 'utils/validateEmail';
import { phoneRE } from 'utils/validatePhone';
import './styles.scss';

const Profile = () => {

  //#region hooks
  const { user, credentials = {} } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //#endregion

  const title = t('profile.title');
  const [profileForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const hasPassword = credentials?.password;

  console.log(credentials);


  const accounts = [{
    name: 'facebook', label: 'Facebook', icon: 'fa-facebook-f',
    img: data => `http://graph.facebook.com/${data?.id}/picture?type=square`,
    link: data => `https://www.facebook.com/profile.php?id=${data?.id}`,
  }, {
    name: 'google', label: 'Google', icon: 'fa-google',
    img: data => data?.picture,
    link: data => `mailto:${data?.linkedUsername}`,
  }].map(({ name, ...more }) => ({
    name,
    data: credentials[name],
    ...more,
  })).map(({ link, img, data, name, ...more }) => ({
    name,
    data,
    link: link(data),
    img: img(data),
    toggleLink: data && Object.keys(credentials).length === 1 ? () => Modals.error(t('credentials.unlinkUnique')) : () => {
      dispatch(data ? unlink(name) : linkWith(name));
    },
    ...more,
  }));

  const phoneValidator = async (rule, value) => {
    if (!rule.required && !value) return;
    if (!phoneRE.test(value.replace(/ |-/g, ''))) throw new Error('pattern');
  };

  const passwordValidator = async (rule, value) => {
    if (value !== passwordForm.getFieldValue('newPassword')) throw new Error('equals');
  };

  const newPasswordChanged = () => {
    if (passwordForm.getFieldValue('retypePassword')) passwordForm.validateFields(['retypePassword'])
  };

  const updateProfile = () => {
    profileForm.validateFields().then(() => {
      dispatch(editUser(profileForm.getFieldsValue()));
    }, noop);
  };

  const updatePassword = () => {
    passwordForm.validateFields().then(function () {
      const password = passwordForm.getFieldValue('newPassword');
      const current_password = passwordForm.getFieldValue('oldPassword');
      dispatch(userUpdatePassword({ password, current_password }));
    }).catch(noop);
  }

  const logout = () => dispatch(signout());

  return <>
    <Toolbar title={title} />
    <Content>
      <div className="profile_page common_page">
        <div className="header-image" style={{ backgroundImage: 'url(/img/profile_header.png)' }}>
          <div className="block-container">
            <button className="flat-btn logout" onClick={logout}><i className="fas fa-sign-out-alt"></i></button>
            <h1 className="hide-md">{title}</h1>
          </div>
        </div>
        <Breadcrumbs page={title} />
        <div className="block-container">
          <div className="small-container">
            <div className="empty_space hide-md"></div>
            {!user ? null : <Tabbed btns={[t('profile.title'), t('credentials.title'), t('notifications.title')]} panels={[
              <Card title={t('profile.title')}>
                <Form form={profileForm}>
                  <label>{t('userInfo.firstName')}</label>
                  <Form.Item
                    name="firstName"
                    initialValue={user.firstName}
                    rules={[{ required: true, message: t('userInfo.firstNameMsg') }]}
                  ><Input /></Form.Item>

                  <label>{t('userInfo.lastName')}</label>
                  <Form.Item
                    name="lastName"
                    initialValue={user.lastName}
                    rules={[{ required: true, message: t('userInfo.lastNameMsg') }]}
                  ><Input /></Form.Item>

                  <label>{t('userInfo.company')}</label>
                  <Form.Item
                    name="company"
                    initialValue={user.attributes?.company?.[0]}
                    rules={[{ required: true, message: t('userInfo.companyMsg') }]}
                  ><Input /></Form.Item>

                  <div style={{ paddingBottom: 10 }}><Checkbox>{t('userInfo.useCompany')}</Checkbox></div>

                  <label>{t('userInfo.phone')}</label>
                  <Form.Item
                    name="phone1"
                    initialValue={user.attributes?.phone1?.[0]}
                    rules={[{ required: true, validator: phoneValidator, message: t('userInfo.phoneMsg') }]}
                  ><Input type="tel" placeholder="123 456-789" /></Form.Item>

                  <label>{t('userInfo.phone2')}</label>
                  <Form.Item
                    name="phone2"
                    initialValue={user.attributes?.phone2?.[0]}
                    rules={[{ validator: phoneValidator, message: t('userInfo.phone2Msg') }]}
                  ><Input type="tel" placeholder="123 456-789" /></Form.Item>

                  <label>{t('userInfo.email')}</label>
                  <Form.Item
                    name="email"
                    initialValue={user.email}
                    rules={[{ required: true, pattern: emailRE, message: t('userInfo.emailMsg') }]}
                  ><Input type="email" placeholder="user@example.com" /></Form.Item>

                  <label>{t('userInfo.email2')}</label>
                  <Form.Item
                    name="email2"
                    initialValue={user.attributes?.email2?.[0]}
                    rules={[{ pattern: emailRE, message: t('userInfo.email2Msg') }]}
                  ><Input type="email" placeholder="user@example.com" /></Form.Item>

                </Form>
                <div className="card-btn"><span onClick={updateProfile}>{t('save')}</span></div>
              </Card>,
              <Card title={t('credentials.title')}>
                {user ? <>
                  <Form form={passwordForm}>{hasPassword
                    ? <><label>{t('userInfo.oldPassword')}</label>
                      <Form.Item
                        name="oldPassword"
                        rules={[{ required: true, min: 6, message: t('userInfo.oldPasswordMsg') }]}
                      ><Input.Password placeholder="••••••" /></Form.Item></>
                    : null}
                    <label>{t('userInfo.newPassword')}</label>
                    <Form.Item
                      name="newPassword"
                      rules={[{ required: true, min: 6, message: t('userInfo.newPasswordMsg') }]}
                    ><Input.Password placeholder="••••••" onChange={newPasswordChanged} /></Form.Item>

                    <label>{t('userInfo.retypePassword')}</label>
                    <Form.Item
                      name="retypePassword"
                      rules={[{ required: true, validator: passwordValidator, message: t('userInfo.retypePasswordMsg') }]}
                    ><Input.Password placeholder="••••••" /></Form.Item>
                  </Form>
                  <div className="card-btn"><span onClick={updatePassword}>{hasPassword ? t('save') : t('password.add')}</span></div>
                  <div />
                  {accounts.map(({ name, img, icon, label, data, link, toggleLink }) => <div key={name} className="sn-accounts">
                    <h4 className="switch_container"><i className={`fab ${icon} ${name}-fg`}></i> {label} Account <span className="switch"><Switch checked={!!data} onClick={toggleLink} /></span></h4>
                    {data ? <div className="sn-account">
                      <a href={link}>
                        <img src={img} alt={data.name} />
                        <span className={`${name}-fg`}>{data.name}</span>
                      </a>
                    </div> : null}
                  </div>)}
                </> : null}
              </Card>,
              <Card title={t('notifications.title')}>
                {user ? <>
                  <div>
                    <h4 className="switch_container">{t('profile.callingCard')} <span className="switch"><Switch /></span></h4>
                    <h4 className="switch_container">{t('profile.promotions')} <span className="switch"><Switch /></span></h4>
                  </div>
                  <div className="card-btn"><span>{t('save')}</span></div>
                </> : null}
              </Card>,
            ]} />}
          </div>
        </div>
      </div>
    </Content>
  </>;
}

export default withMenu(Profile)
