import fragments from "fragments";
import gql from "graphql-tag";

export const GET_HOME_DATA = gql`
  query Home {
    deals(
      first: 1
      orderBy: [{column: UPDATED_AT, order: DESC}]
    ) {
      data {
        ...DealFragment
      }
    }
    transaction: transactions(
      first: 1
      orderBy: [{column: ID, order: DESC}]
    ) {
      data {
        ...TransactionFragment
      }
    },
    transactions(
      first: 3
      orderBy: [{column: ID, order: DESC}]
      hasStatus: {column: SLUG, value: ["paid", "refunded"], operator: NOT_IN}
    ) {
      data {
        ...TransactionFragment
      }
    }
  }
  ${fragments.address}
  ${fragments.deal}
  ${fragments.transaction}
`;