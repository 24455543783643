import {
  SET_PROPERTIES,
  SET_PROPERTY_TO_EDIT,
  SET_LATLNG,
} from './actionTypes';

export const setProperties = (properties) => ({
  type: SET_PROPERTIES,
  payload: properties
});

//#region Property to edit

export const setPropertyToEdit = (property) => ({
  type: SET_PROPERTY_TO_EDIT,
  payload: property
});

//#endregion Property to edit

export const setLatLng = (latLng) => ({
  type: SET_LATLNG,
  payload: latLng
})

export const deleteProperty = (id) => (dispatch, getStore) => {
  // TODO here we should get the property from the api request
  const properties = getStore().properties.properties.filter(property => property.id + '' !== id);
  dispatch(setProperties(properties));
}

export const addProperty = (name, address, latLng) => (dispatch, getStore) => {
  // TODO here we should get the property from the api request 
  const properties = getStore().properties.properties;

  properties.push({
    id: properties.length > 0 ? properties[properties.length - 1].id + 1 : 0,
    name,
    address,
    latLng,
    contracts: [],
  })

  dispatch(setProperties(properties));
}
