import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import { SafetyOutlined } from '@ant-design/icons';

import './styles.scss';

const Pointer = () => <SafetyOutlined className='pointer' />;

const SimpleMap = (props) => {
  const { latLng, onLatLngChange } = props;
  console.log(latLng)
  const onClick = (e) => {
    onLatLngChange({ lat: e.lat, lng: e.lng })
  }

  return (
    <div className='map_container'>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultZoom={20}
        center={latLng}
        onClick={onClick}
      >
        <Pointer
          lat={latLng.lat}
          lng={latLng.lng}
        />
      </GoogleMapReact>
    </div>
  );
}

SimpleMap.propTypes = {
  latLng: PropTypes.object,
  onLatLngChange: PropTypes.func
}

export default SimpleMap;