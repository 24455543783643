import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import { noop } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addPaymentMethod, updatePaymentMethod } from 'store/payments/actions';


const AutoDebit = ({ card, afterAdd, isEdit, children }) => {

  //#region hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //#endregion

  //#region click handlers
  const add = () => {
    form.validateFields().then(() => {
      const data = form.getFieldsValue();
      dispatch(addPaymentMethod({ makeId: id => `m${id}`, data: { type: 'monthly', ...data, } }));
      afterAdd();
    }, noop);
  }
  const save = () => {
    form.validateFields().then(() => {
      const data = form.getFieldsValue();
      dispatch(updatePaymentMethod({ ...card, ...data, }));
    }, noop);
  }
  //#endregion

  //#region change handlers
  const handleFieldChange = (field, norm, value) => {
    const normValue = norm(value);
    if (normValue !== value) {
      form.setFieldsValue({
        [field]: normValue
      });
      form.validateFields([field]);
    };
  };
  const handleNumber = field => e => handleFieldChange(field, s => s.replace(/\D+/g, ''), e.target.value);
  //#endregion

  //#region View
  const [form] = Form.useForm();
  const isNew = typeof card.id !== 'string';

  return <>
    <Form form={form} name={card.id}>
      <label>{t('card.auto')}</label>
      <Form.Item
        name="name"
        initialValue={card.name}
        rules={[{ required: true, message: t('card.name.required') }]}
      ><Input readOnly={!isEdit} placeholder={t('card.name.placeholder')} /></Form.Item>
      <label>{t('card.address.label')}</label>
      <Form.Item
        name="address"
        initialValue={card.address}
        rules={[{ required: true, message: t('card.address.required') }]}
      ><Input.TextArea readOnly={!isEdit} autoSize /></Form.Item>
      <div className="horizontal_fields">
        <div>
          <label>{t('card.transit.label')}</label>
          <Form.Item
            name="transit"
            initialValue={card.transit}
            rules={[{ required: true, pattern: /^\d{5}$/, message: t('card.transit.required') }]}
          ><Input readOnly={!isEdit} maxLength={5} onChange={handleNumber('transit')} placeholder="XXXXX" /></Form.Item>
        </div>
        <div>
          <label>{t('card.instit.label')}</label>
          <Form.Item
            name="instit"
            initialValue={card.instit}
            rules={[{ required: true, pattern: /^\d{3}$/, message: t('card.instit.required') }]}
          ><Input readOnly={!isEdit} maxLength={3} onChange={handleNumber('instit')} placeholder="XXX" /></Form.Item>
        </div>
        <div>
          <label>{t('card.accountNb.label')}</label>
          <Form.Item
            name="accountNb"
            initialValue={card.accountNb}
            rules={[{ required: true, pattern: /^\d{7}$/, message: t('card.accountNb.required') }]}
          ><Input readOnly={!isEdit} maxLength={7} onChange={handleNumber('accountNb')} placeholder="XXXXXXX" /></Form.Item>
        </div>
      </div>
    </Form>
    <div>
      {isEdit?<div className="card-btn"><span onClick={!isNew ? save : add}>{!isNew ? t('save') : t('add')}</span></div>:null}
      {children}
    </div>
  </>;
  //#endregion
}
export default AutoDebit;