import Nav from "pages/container/header/Nav";
import React from 'react';
import { useTranslation } from "react-i18next";
import './styles.scss';

const Breadcrumbs = ({ page, parents = [] }) => {
  const { t } = useTranslation();
  return <div className="breadcrumbs">
    <div className="block-container">
      <Nav path="/" name="home.title" exact />
      {parents.concat([{ name: page }]).map((nav, i) => <span key={i} className="home_item">{
        nav.path ? <Nav {...nav} exact /> : t(nav.name)
      }</span>)}
    </div>
  </div>;
}

export default Breadcrumbs;