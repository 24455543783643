import axios from 'axios';
import base64url from 'base64url';
import { sha256 } from 'js-sha256';
import Keycloak from 'keycloak-js';
import { v4 as uuidv4 } from 'uuid';
import qs from 'qs';



export const parseToken = token => JSON.parse(decodeURIComponent(escape(base64url.decode(token.split('.')[1]))))
export const formContentType = { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' }

/** @typedef {Keycloak.KeycloakInstance} KeycloakCtr */
/** @type {new ()=>Keycloak.KeycloakInstance}  */
const KeycloakCtr = Keycloak;

export class ExtendedKeycloak extends KeycloakCtr {
  constructor(config) {
    super(config)

    const { clientId, isLoggable, realm, url: authServerUrl } = config
    this.clientId = clientId
    this.isLoggable = isLoggable
    this.realmUrl = `${authServerUrl}/realms/${realm}`
    this.accountLink = `${this.realmUrl}/account`
    this.credentialsLink = `${this.accountLink}/credentials`
    this.linkedAccounts = `${this.accountLink}/linked-accounts`
    this.tokenLink = `${this.realmUrl}/protocol/openid-connect/token`
  }
  getBrokerUrl(broker) {
    return `${this.realmUrl}/broker/${broker}`
  }
  createLinkUrl({ redirectUri, broker }) {
    const nonce = uuidv4()
    const hash = base64url(sha256.arrayBuffer(`${nonce}${this.sessionId}${this.clientId}${broker}`))
    return `${this.getBrokerUrl(broker)}/link?client_id=${this.clientId}&redirect_uri=${redirectUri}&nonce=${nonce}&hash=${hash}`
  }
  async loginWithPassword({ username, password, }, handler = ({ access_token }) => {
    const parsed = parseToken(access_token);
    if (!this.isLoggable(parsed)) {
      throw new Error('not loggable');
    }
  }) {
    const timeLocal = new Date().getTime();
    try {
      const { data: result } = await axios.post(this.tokenLink, qs.stringify({
        client_id: this.clientId,
        grant_type: 'password',
        username,
        password,
      }), { headers: formContentType })
      handler?.(result)
      const { refresh_token, access_token } = result
      // estimate the local time of the request remote processing as the middle of send and recieve time
      this.setToken(access_token, refresh_token, (timeLocal + new Date().getTime()) / 2)
      this.onAuthRefreshSuccess?.()
    } catch (e) {
      this.clearToken()
      throw e
    }
  }

  getHeaders(token) {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + (token ?? this.token),
    }
  }
  getBrokerTokenUrl(broker) {
    return `${this.getBrokerUrl(broker)}/token`
  }
  getOptions(token) {
    return { headers: this.getHeaders(token), withCredentials: false };
  }
  getCredentials() {
    return axios.get(this.credentialsLink, this.getOptions())
  }
  getLinkedAccounts() {
    return axios.get(this.linkedAccounts, this.getOptions())
  }
  getBrokerToken(provider) {
    return axios.get(this.getBrokerTokenUrl(provider), this.getOptions())
  }
  updateUserAccount(data) {
    return axios.post(this.accountLink, data, this.getOptions())
  }
  getUserProfile() {
    return axios.get(this.accountLink, this.getOptions())
  }
  // expose keycloak private function
  setToken(token, refreshToken, timeLocal) {
    const kc = this;
    if (kc.tokenTimeoutHandle) {
      clearTimeout(kc.tokenTimeoutHandle);
      kc.tokenTimeoutHandle = null;
    }

    if (refreshToken) {
      kc.refreshToken = refreshToken;
      kc.refreshTokenParsed = parseToken(refreshToken);
    } else {
      delete kc.refreshToken;
      delete kc.refreshTokenParsed;
    }

    if (token) {
      kc.token = token;
      kc.tokenParsed = parseToken(token);
      kc.sessionId = kc.tokenParsed.session_state;
      kc.authenticated = true;
      kc.subject = kc.tokenParsed.sub;
      kc.realmAccess = kc.tokenParsed.realm_access;
      kc.resourceAccess = kc.tokenParsed.resource_access;

      if (timeLocal != null) {
        kc.timeSkew = Math.floor(timeLocal / 1000) - kc.tokenParsed.iat;
      }

      if (kc.timeSkew != null) {
        if (kc.onTokenExpired) {
          var expiresIn = (kc.tokenParsed['exp'] - (new Date().getTime() / 1000) + kc.timeSkew) * 1000;
          if (expiresIn <= 0) {
            kc.onTokenExpired();
          } else {
            kc.tokenTimeoutHandle = setTimeout(kc.onTokenExpired, expiresIn);
          }
        }
      }
    } else {
      delete kc.token;
      delete kc.tokenParsed;
      delete kc.subject;
      delete kc.realmAccess;
      delete kc.resourceAccess;

      kc.authenticated = false;
    }
  }
}

export default ExtendedKeycloak
