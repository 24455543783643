import {
  SET_PROPERTIES,
  SET_PROPERTY_TO_EDIT,
  SET_LATLNG,
  UPDATE_PROPERTY
} from './actionTypes';
import initialState from './initialState';

const setProperties = (state, action) => {
  return {
    ...state,
    properties: action.payload
  }
}

const setPropertyToEdit = (state, action) => {
  return {
    ...state,
    propertyToEdit: action.payload
  }
}

const setLatLng = (state, action) => {
  return {
    ...state,
    propertyToEdit: {
      ...state.propertyToEdit,
      latLng: action.payload
    }
  }
}

const updateProperty = (state, action) => {
  const properties = [...state.properties];
  
  properties.forEach((property, index) => {
    if (property.id === action.payload.id) properties[index] = action.payload;
  })

  return {
    ...state,
    properties
  }
}

const actionHandler = {
  [SET_PROPERTIES]: setProperties,
  [SET_PROPERTY_TO_EDIT]: setPropertyToEdit,
  [SET_LATLNG]: setLatLng,
  [UPDATE_PROPERTY]: updateProperty
}
/**  @returns {typeof initialState} */
const reducer = (state = initialState, action) => {
  const handler = actionHandler[action.type];

  return handler ? handler(state, action) : state;
}

export default reducer;