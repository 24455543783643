import {
  SET_CRED,
  SET_USER,
  SET_LOADING,
  SET_TOKEN,
  SET_NEED_CRED,
  SET_FIRST_LOGIN,
  SET_CRED_LOADING,
} from './actionTypes';
import { userInitialState } from './initialState';

const setUser = (state, action) => {
  return {
    ...state,
    user: action.payload
  }
}

const setToken = (state, action) => {
  return {
    ...state,
    token: action.payload,
    loading: false,
    firstLoginPending: false,
  }
}

const setCredentials = (state, action) => {
  return {
    ...state,
    credentials: action.payload,
    needCredentials: false,
    credentialsPending: false,
  }
}
const setLoading = (state, action) => {
  return {
    ...state,
    loading: action.payload
  }
}
const setFirstLoginPending = (state, action) => {
  return {
    ...state,
    firstLoginPending: action.payload
  }
}

const setNeedCredentials = (state, action) => {
  return {
    ...state,
    needCredentials: action.payload
  }
}

const setCredLoading = (state, action) => {
  return {
    ...state,
    credentialsPending: action.payload
  }
}

const actionHandler = {
  [SET_FIRST_LOGIN]: setFirstLoginPending,
  [SET_USER]: setUser,
  [SET_TOKEN]: setToken,
  [SET_CRED]: setCredentials,
  [SET_NEED_CRED]: setNeedCredentials,
  [SET_LOADING]: setLoading,
  [SET_CRED_LOADING]: setCredLoading,
}
/**
 * @returns {import('./initialState').UserState}
 */
const reducer = (state = userInitialState, action) => {
  const handler = actionHandler[action.type];

  return handler ? handler(state, action) : state;
}

export default reducer;