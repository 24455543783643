import React from 'react';
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-places-autocomplete';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * @param {Object} props
 * @param {(
 *  address: string, 
 *  latLng: google.maps.LatLngLiteral,
 *  result: google.maps.GeocoderResult
 * )=>void} props.onChange 
 * @param {string} props.address 
 */
const LocationSearchInput = ({ onChange, address }) => {

  //#region Change handlers
  const handleChange = address => onChange(address);

  const handleSelect = async (address, placeId) => {
    geocodeByPlaceId(placeId)
      .then(results => Promise.all([results, getLatLng(results[0])]))
      .then(([results, latLng]) => onChange(address, latLng, results[0]))
      .catch(error => console.error('Error', error));
  }
  //#endregion Change handlers

  const renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
    <div className="autocomplete_root">
      <input {...getInputProps()} />
      <div className="autocomplete_dropdown_container">
        {suggestions.map((suggestion, index) => (
          <div key={index} {...getSuggestionItemProps(suggestion)}>
            <span>{suggestion.description}</span>
          </div>
        ))}
      </div>
    </div>
  );
  
  return (
    <PlacesAutocomplete
      value={address}
      onSelect={handleSelect}
      onChange={handleChange}
    >
      {renderFunc}
    </PlacesAutocomplete>
  );
}

LocationSearchInput.propTypes = {
  onChange: PropTypes.func,
  address: PropTypes.string
}

export default LocationSearchInput