import React from 'react';
import { Trans } from 'react-i18next';
import Header from './header';
import './styles.scss';

const Container = ({ children, withHeaderMenu }) => {
  return <>
    <Header withHeaderMenu={withHeaderMenu} />
    {children}
    <div className="page-footer">
      <div className="block-container">
        <div><Trans i18nKey="layout.footer">
          From Saguenay to Quebec, via Sherbrooke and Montreal, our customers are close to our hearts<br />
          2021 <a href="/">cGreen</a> - All rights reserved - <a href="/">Politique de confidentialité</a>
        </Trans></div>
        <div className="sn-logos">
          <span className="link"><img alt="facebook" src="/img/fb-logo.png" /></span>
          <span className="link"><img alt="twitter" src="/img/twitter-logo.png" /></span>
          <span className="link"><img alt="google" src="/img/google-logo.png" /></span>
        </div>
      </div>
    </div>
  </>;
};
export default Container;