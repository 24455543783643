import Spin from 'antd/lib/spin';
import Breadcrumbs from 'components/breadcrumbs';
import withMenu from 'hoc/with-menu';
import moment from 'moment';
import Content from 'pages/container/content';
import Toolbar from 'pages/container/toolbar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import Status from '../Status';
import { GET_DEAL_DETAILS } from '../queries';
import { saleItemCatalogImages } from './sale-catalog-images';

import 'styles/_common_page.scss';
import '../styles.scss';
import './styles.scss';

const ContractTasks = (props) => {
  const contractId = props.match.params.id;
  const dealsQuery = useQuery(GET_DEAL_DETAILS, { variables: { dealId: contractId } })
  const { data: { projects: { data: projects = [] } = {} } = {}, loading } = dealsQuery
  const tasks = _.chain(projects).flatMap(item => item.tasks.map(task => ({
    imageId: saleItemCatalogImages[item.line_item?.saleCatalogItem?.id] ?? 'fertilization',
    status: ['waiting', 'done', 'waiting', 'canceled', 'inProgress'][task.status?.id >> 4],
    title: task.title,
    date: task.updated_at
  }))).value()
  const hideServiceCall = true;

  const { t } = useTranslation();

  return <>
    <Toolbar title={t('contracts.single') + ' # ' + contractId} />
    <Content>
      <div className="contracts_page common_page">
        <div className="header-image" style={{ backgroundImage: 'url(/img/contracts_header.png)' }}>
          <div className="block-container">
            <h1 className="hide-md">{t('contracts.title')}</h1>
          </div>
        </div>
        <Breadcrumbs page={`#${contractId}`} parents={[{ name: 'contracts.title', path: '/contracts' }]} />
        <div className="block-container">
          <h3 className="group hide-md">{t('contracts.single')} #{contractId}</h3>
          <div className="tasks">
            {loading ? <Spin /> : tasks?.length ? tasks.map(({ imageId, status, date, title }, i) => <div key={i}>
              <img alt={title} src={`/img/tasks/${imageId}.png`} key={i} />
              <div>
                <h1>{title}</h1>
                <Status status={status} inline />
                {date ? <span style={{ marginLeft: 10 }}>{moment(date).format('ll')}</span> : null}
              </div>
            </div>) : t('none')}
          </div>
          {hideServiceCall ? null : <div>
            <div className="card-btn"><span>{t('contracts.serviceCall')}</span></div>
          </div>}
        </div>
      </div>
    </Content>
  </>;
}

export default withMenu(ContractTasks);
