// import './styles.scss';
import BottomBarMenu from 'components/bottom-bar-menu';
import { useRedirect } from 'hook/useRedirect';
import Container from 'pages/container';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

/**
 * Shows the wrapped component if the user can access it or, elsewhere, shows 
 * a splash screen waiting for a redirect to happen or the user to be authenticated
 * @param {React.Component} Component the component to render
 * @param {'register'|'login'|'user'} route the current route
 * @returns {React.Component} the passed component or a splash screen
 */
const withMenu = (Component, route = 'user') => (props) => {
  const token = useSelector(state => state.user.token);
  const credentials = useSelector(state => state.user.credentials);
  const needCredentials = useSelector(state => state.user.needCredentials);

  const lastRender = useRef('loading...');
  const toUrl = useRedirect(route);
  if (token === undefined) return lastRender.current = <Container />;
  if (token && !credentials && needCredentials) return <Container />;
  if (toUrl === '/login') return lastRender.current = <Container />;
  return lastRender.current = <Container withHeaderMenu={route === 'user'}>
    <Component {...props} />{
      route === 'user' ? <BottomBarMenu /> : <></>
    }</Container>;
}

export default withMenu
