import Modals from 'components/modals';
import withMenu from 'hoc/with-menu';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { setPropertyToEdit } from 'store/properties/actions';
import { CREATE_ADDRESS } from './queries';
import { propertyToAddress } from 'utils/addressToProperty';
import PropertyForm from '../property-form';
import initialState from 'store/properties/initialState';


const AddProperty = (props) => {
  //#region queries
  const [createProperty] = useMutation(CREATE_ADDRESS, {
    onCompleted: () => {
      Modals.success(t('property.added'))
    },
    onError: (e) => {
      Modals.error(t('error'), e.message)
    },
    optimisticResponse: () => {
      return { ...propertyToAddress(propertyToEdit), id: '...' }
    },
    update: (cache, { data: { createAddress: created } }) => {
      cache.modify({
        fields: {
          addresses(value, { toReference }) {
            return { ...value, data: [toReference(created ?? propertyToAddress(propertyToEdit), true), ...value.data] };
          }
        }
      });
    }
  })
  //#endregion

  //#region hooks
  const dispatch = useDispatch();
  const propertyToEdit = useSelector(state => state.properties.propertyToEdit);
  const [input, setInput] = useState({ address: null, fromMap: true })
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setPropertyToEdit(initialState.propertyToEdit));
    setInput({ address: '', fromMap: true })
  }, [dispatch])
  //#endregion

  //#region handlers
  const onSubmit = () => {
    createProperty({ variables: _.omit(propertyToAddress(propertyToEdit), 'id') })
    props.history.push('/properties')
  }
  //#endregion

  return <PropertyForm onSubmit={onSubmit} input={input} setInput={setInput} />
}

export default withMenu(AddProperty);
