import React from 'react';
import './styles.scss';

const Card = ({ img, title, children }) => {
  return <div className="tabbed-card">
    {img ? <img alt="" src={img} className="hide-md" /> : null}
    <div>
      {title ? <div className="title">{title}</div> : null}
      {children}
    </div>
  </div>;
}

export default Card;