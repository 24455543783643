import React from 'react';

import Modal from 'antd/lib/modal';
import { QuestionCircleOutlined } from '@ant-design/icons';

import './styles.scss';

export const info = (title, content) => {
  Modal.info({
    title: title,
    content: (
      <div>
        <p>{content}</p>
      </div>
    ),
    onOk() {},
  });
}

export const success = (successMessage) => {
  Modal.success({
    content: successMessage,
  });
}

export const error = (title, content) => {
  Modal.error({
    title,
    content
  });
}

export const warning = (title, content) => {
  Modal.warning({
    title,
    content: content
  });
}

export const deleting = (title, content, confirmDelete) => {
  Modal.confirm({
    title,
    icon: <QuestionCircleOutlined />,
    content,
    onOk: confirmDelete,
    onCancel: () => console.log('CANCEL')
  });
}