import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';

const Toolbar = ({ title, back }) => {
  const history = useHistory();
  const { location: { pathname } } = history;
  const home = pathname === '/';
  const goback = useCallback(
    () => history.push(back || pathname.split('/').slice(0, -1).join('/') || '/'), 
    [history, pathname, back]);
  return <div className="toolbar"><span>{home ? <></> : <span className="back" onClick={goback}>🡠</span> }</span><span className="title">{title}</span><span /></div>;
}
export default Toolbar;