import Breadcrumbs from 'components/breadcrumbs';
import Tabbed from 'components/tabbed';
import withMenu from 'hoc/with-menu';
import Content from 'pages/container/content';
import Toolbar from 'pages/container/toolbar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import 'styles/_common_page.scss';
import BillingHistory from './history';
import PaymentModes from './modes';
import './styles.scss';


const routes = ['/billing', '/billing/history'];
const pages = [PaymentModes, BillingHistory];

const Billing = () => {

  //#region hooks
  const { t } = useTranslation();
  const history = useHistory();
  //#endregion

  const titles = ['billing.paymentModes', 'billing.history'];
  const selected = routes.indexOf(history.location.pathname);
  const Page = pages[selected];
  const setSelected = selected => history.replace(routes[selected])

  const title = t('billing.title');
  return <>
    <Toolbar title={title} back="/" />
    <Content>
      <div className="billing_page common_page">
        <div className="header-image" style={{ backgroundImage: 'url(/img/billing_header.png)' }}>
          <div className="block-container">
            <h1 className="hide-md">{title}</h1>
          </div>
        </div>
        <Breadcrumbs page={titles[selected]} parents={[{ name: 'billing.title', path: selected ? '/billing' : '' }]} />
        <div className="block-container">
          <div className="empty_space hide-md" />
          <div className="small-container">
            <Tabbed btns={titles.map(t)} panels={[]} isShown onSelect={setSelected} selected={selected} />
          </div>
        </div>
        <Page />
      </div>
    </Content>
  </>;
}
export default withMenu(Billing);