import { ApolloProvider } from '@apollo/client';
import { QueryClientProvider } from 'react-query'
import { useKeycloak } from 'hook/useKeycloak';
import AddProperty from 'pages/properties/add-property';
import Auth from 'pages/auth';
import Billing from 'pages/billing';
import Contracts from 'pages/contracts';
import ContractTasks from 'pages/contracts/contract-tasks';
import EditProperty from 'pages/properties/edit-property';
import Home from 'pages/home';
import Login from 'pages/login/Login';
import Profile from 'pages/profile';
import Properties from 'pages/properties';
import Signup from 'pages/signup';
import { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './i18n';
import store from './store';
import client, { queryClient } from './config/client'


const Routes = withTranslation()(() => {
  useKeycloak();
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Home} path='/' exact />
        <Route component={Profile} path='/profile' exact />
        <Route component={Properties} path='/properties' exact />
        <Route component={Billing} path='/billing' />
        <Route component={EditProperty} path='/properties/edit/:id' exact />
        <Route component={Contracts} path='/contracts' exact />
        <Route component={ContractTasks} path='/contracts/:id' exact />
        <Route component={AddProperty} path='/properties/add' exact />
        <Route component={Login} path='/login' exact />
        <Route component={Signup} path='/signup' exact />
        <Route component={Auth} path='/auth' exact />
        <Route render={() => <Redirect to='/' />} />
      </Switch>
    </BrowserRouter>
  );
});


function App() {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Suspense fallback={<div></div>}>
            <Routes />
          </Suspense>
        </Provider>
      </QueryClientProvider>
    </ApolloProvider>
  );
}


export default App;
