import { useCallback } from "react";
import { useListener } from "./useListener";

const getScroll = () => {
  const bodyOffset = document.body.getBoundingClientRect()
  return { scrollY: -bodyOffset.top, setScrollX: bodyOffset.left };
}
export function useWindowScroll(compute = ({ scrollX, scrollY }) => 0) {
  const listener = useCallback(() => compute(getScroll()), [compute]);
  return useListener('scroll', listener);
}
