import { useMemo, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";
import { useCb } from "./useListener";

export function useResize(element, compute = ({ contentRect: { width, height } }) => 0) {
  const observer = useRef();
  const [start, stop] = useMemo(() => [cb => {
    observer.current = new ResizeObserver((entries) => {
      if (!entries?.length) return;
      cb(entries[0]);
    });
    if(element) observer.current.observe(element);
  }, () => {
    if(element) observer.current.unobserve(element);
  }], [element]);

  return useCb(start, stop, compute)
}
