import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import { noop } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addPaymentMethod, updatePaymentMethod } from 'store/payments/actions';
import { normDigitBlocks } from 'utils/normDigitBlocks';


const normCardId = normDigitBlocks(...[, , , '0-9•']);
const normExpires = normDigitBlocks(2, '/', 2);

const CreditCard = ({ card, afterAdd, isEdit, children }) => {

  //#region hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //#endregion

  //#region click handlers
  const add = () => {
    form.validateFields().then(() => {
      const data = form.getFieldsValue();
      dispatch(addPaymentMethod({ makeId: id => `crc${id}`, data: { type: 'credit-card', ...data, cardId: data.cardId?.replace(/\s+/g, '') } }));
      afterAdd();
    }, noop);
  }
  const save = () => {
    form.validateFields().then(() => {
      const data = form.getFieldsValue();
      dispatch(updatePaymentMethod({ ...card, ...data, cardId: data.cardId?.replace(/\s+/g, '') }));
    }, noop);
  }
  //#endregion

  //#region change handlers
  const handleFieldChange = (field, norm, value) => {
    const normValue = norm(value);
    if (normValue !== value) {
      form.setFieldsValue({
        [field]: normValue
      });
      form.validateFields([field]);
    };
  };
  const handleCardIdChange = e => handleFieldChange('cardId', normCardId, e.target.value);
  const handleExpiresChange = e => handleFieldChange('expires', normExpires, e.target.value);
  const handleCodeChange = e => handleFieldChange('code', s => s.replace(/\D+/g, ''), e.target.value);

  //#endregion

  //#region View
  const [form] = Form.useForm();
  const isNew = typeof card.id !== 'string';

  return <>
    <Form form={form} name={card.id}>
      <label>{t('card.name.label')}</label>
      <Form.Item
        name="name"
        initialValue={card.name}
        rules={[{ required: true, message: t('card.name.required') }]}
      ><Input readOnly={!isEdit} placeholder={t('card.name.placeholder')} /></Form.Item>
      <label>{t('card.cardNumber.label')}</label>
      <Form.Item
        name="cardId"
        initialValue={normCardId(card.cardId)}
        rules={[{ required: true, pattern: /^\d{4}(\s\d{4}){3}$/, message: t('card.cardNumber.required') }]}
      ><Input readOnly={!isEdit} style={{
        background: 'url(/img/card/visa_logo.png) no-repeat right',
        paddingRight: 56
      }} maxLength={19} onChange={handleCardIdChange} placeholder="XXXX XXXX XXXX XXXX" /></Form.Item>
      <div className="horizontal_fields">
        <div>
          <label>{t('card.expires.label')}</label>
          <Form.Item
            name="expires"
            initialValue={card.expires}
            rules={[{ required: true, pattern: /^(0?[1-9]|1[0-2])\/[2-5]\d$/, message: t('card.expires.required') }]}
          ><Input readOnly={!isEdit} maxLength={5} onChange={handleExpiresChange} placeholder="MM/YY" /></Form.Item>
        </div>
        <div>
          <label>{t('card.code.label')}</label>
          <Form.Item
            name="code"
            initialValue={card.code}
            rules={[{ required: true, pattern: /^\d{4}$/, message: t('card.code.required') }]}
          ><Input.Password readOnly={!isEdit} maxLength={4} onChange={handleCodeChange} placeholder="XXXX" /></Form.Item>
        </div>
      </div>
    </Form>
    <div>
      {isEdit ? <div className="card-btn"><span onClick={!isNew ? save : add}>{!isNew ? t('save') : t('add')}</span></div> : null}
      {children}
    </div>
  </>;
  //#endregion
}
export default CreditCard;