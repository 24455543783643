import Modals from 'components/modals';
import {
  SET_PAYMENTS,
} from './actionTypes';
import i18n from '../../i18n';

export const deletePaymentMethod = (id) => ((dispatch, getStore) => {
  const { payments } = getStore();
  const methods = payments.methods.slice();
  const idx = methods.findIndex(method => method.id === id);
  methods.splice(idx, 1);
  const chain = payments.chain < idx ? payments.chain : payments.chain - 1;

  dispatch(setPayments({ ...payments, methods, chain }));
})

export const addPaymentMethod = ({ data, makeId }) => ((dispatch, getStore) => {
  const { payments } = getStore();

  const nextId = payments.nextId + 1;
  const id = makeId(nextId);
  const methods = [...payments.methods, { ...data, id }];
  const currentId = id;

  Modals.success(i18n.t('card.added'));

  dispatch(setPayments({ ...payments, methods, currentId, nextId }));
})

export const updatePaymentMethod = (method) => ((dispatch, getStore) => {
  const { payments } = getStore(), { methods } = payments;
  const idx = methods.findIndex(m => m.id === method.id);

  Modals.success(i18n.t('card.edited'));

  dispatch(setPayments({
    ...payments,
    methods: [...methods.slice(0, idx), method, ...methods.slice(idx + 1)]
  }));
})

export const setPaymentMethod = (currentId, pos) => ((dispatch, getStore) => {
  const payments = getStore().payments;
  const methods = payments.methods.slice();
  const { chain } = payments;
  const newPos = methods.findIndex(m => m.id === currentId);
  [methods[newPos], methods[pos]] = [methods[pos], methods[newPos]];
  dispatch(setPayments({ ...payments, methods, chain: Math.max(chain, pos) }));
})

//#region Action creators
export const setPayments = (payments) => ({ type: SET_PAYMENTS, payload: payments })
//#endregion Action creators
