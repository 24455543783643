import { useEffect, useMemo, } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

/**
 * Route the user to an available route according to its state (authenticated, guest)
 * When not connected, store the current route to be redirected to after login
 * @param {'register'|'login'|'user'} route the current route
 * @returns {string} the next url to be redirected to in the use effect phase
 */
export const useRedirect = (route) => {
  const history = useHistory();
  const token = useSelector(state => state.user.token);
  const credentials = useSelector(state => state.user.credentials);
  const needCredentials = useSelector(state => state.user.needCredentials);

  // computes the url to be redirected to in the next effect phase
  // as well as the state operation to do :
  // * 'keep': keep state as it is, just replace url
  // * 'store': store current url to be redirected to after login succeeds
  // * 'retrieve': retrieve it and go back to the stored url if it exists or proceed to nextUrl
  // * undefined: do nothing
  /** @type {[]|[string, 'keep'|'store'|'retrieve']} */
  const [toUrl, stateType] = useMemo(() => {
    if (token === undefined || (token && !credentials && needCredentials)) return [];
    const needSignup = token && credentials && Object.keys(credentials).length === 0;
    const inSignup = route === 'register';
    const needLogin = !token;
    const inLogin = route === 'login';
    if (needSignup) {
      if (!inSignup) {
        return ['/signup', 'keep'];
      }
    } else if (needLogin) {
      if (!inLogin) {
        return ['/login', 'store'];
      }
    } else { // needHome
      if (inSignup || inLogin) {
        return ['/', 'retrieve'];
      }
    }
    return [];
  }, [token, credentials, needCredentials, route]);

  useEffect(() => {
    if (stateType === 'keep') {
      history.replace(toUrl);
    } else if (stateType === 'store') {
      const url = history.createHref(history.location);
      const state = history.location.state;
      history.replace(toUrl, { back: { url, state } });
    } else if (stateType === 'retrieve') {
      const { url, state } = history.location.state?.back ?? {};
      history.replace(url ?? toUrl, state);
    }
  }, [history, stateType, toUrl]);
  return toUrl;
};

