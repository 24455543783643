import Spin from 'antd/lib/spin';
import Button from 'antd/lib/button';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import CustomInput from 'components/custom-input';
import SimpleMap from 'components/simple-map';
import Content from 'pages/container/content';
import Toolbar from 'pages/container/toolbar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setLatLng, setPropertyToEdit } from 'store/properties/actions';
import './styles.scss';
import Breadcrumbs from 'components/breadcrumbs';



const PropertyForm = ({ delProperty, deleting, onSubmit, input, setInput, isEdit }) => {

  //#region hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const propertyToEdit = useSelector(state => state.properties.propertyToEdit);
  //#endregion

  //#region Change handlers
  const onNameChange = (e) => {
    const newProperty = { ...propertyToEdit };
    newProperty.name = e.target.value;
    dispatch(setPropertyToEdit(newProperty));
  }

  /**
   * @param {string} address, 
   * @param {google.maps.LatLngLiteral} latLng
   * @param {google.maps.GeocoderResult} result
   */
  const onAddressChange = (address, latLng, result) => {
    setInput({ address, fromMap: !address })
    const newPropertyToEdit = { ...propertyToEdit };
    if (latLng) {
      console.log(result)
      const parts = result.address_components;
      /** @param {string} name @param {'long_name'|'short_name'} param */
      const getPart = (name, param = 'long_name') => parts.find(part => part.types.indexOf(name) !== -1)?.[param] ?? ' '
      const mapping = {
        civic_number: ['street_number'],
        province: ['administrative_area_level_1', 'short_name'],
        street_name: ['route'],
        city: ['locality'],
        country: ['country'],
        postal_code: ['postal_code'],
      }
      const mapped = Object.fromEntries(Object.entries(mapping).map(([key, value]) => [key, getPart(...value)]));
      Object.assign(newPropertyToEdit, mapped);
      newPropertyToEdit.street_type = result.types[0] ?? ' ';
      newPropertyToEdit.address = result.formatted_address;
      newPropertyToEdit.latLng = latLng
      dispatch(setPropertyToEdit(newPropertyToEdit));
      console.log(newPropertyToEdit)
    }
  }

  const onLatLngChange = (latLng) => {
    if (!isEdit) return;
    if (input.fromMap) {
      const google = window.google;
      new google.maps.Geocoder().geocode({ location: latLng }, (results, status) => {
        if (input.fromMap) {
          onAddressChange(input.address, latLng, results[0])
          setInput({ address: results[0].formatted_address, fromMap: true })
        }
      })
    }
    dispatch(setLatLng(latLng))
  };
  //#endregion


  const isNew = !delProperty;
  const title = t(isNew ? 'property.mine' : 'property.new');

  return <>
    <Toolbar title={title} back="/properties" />
    <Content>
      <div className="properties_page common_page">
        <div className="header-image" style={{ backgroundImage: 'url(/img/profile_header.png)' }}>
          <div className="block-container">
            <h1 className="hide-md">{t('properties.title')}</h1>
          </div>
        </div>
        <Breadcrumbs page={isNew ? t('property.new')
          : t('property.title') + (propertyToEdit.name ? ` (${propertyToEdit.name})` : '')}
          parents={[{ name: 'properties.title', path: '/properties' }]} />

        <div className='block-container edit_property'>
          <div className="empty_space hide-md" />
          <div className="small-container">
            <form>
              {input.address === null ? <div><Spin /></div> : <ErrorBoundary>
                <CustomInput
                  type='text'
                  name='name'
                  label={t('property.name.label')}
                  onChange={onNameChange}
                  value={propertyToEdit.name}
                  readOnly={!isEdit}
                />

                <CustomInput
                  label={t('property.address.label')}
                  type='places-autocomplete'
                  value={input.address}
                  onChange={onAddressChange}
                  readOnly={!isEdit}
                />

                <SimpleMap onLatLngChange={onLatLngChange} latLng={propertyToEdit.latLng} />
              </ErrorBoundary>}
              {isEdit ? <Button onClick={onSubmit}>{t(isNew ? 'add' : 'submit')}</Button> : null}
            </form>

            {isNew || !isEdit ? <></>
              : <Button loading={deleting} className='delete_property_btn' onClick={delProperty}>{t('property.deleteBtn')}</Button>}
          </div>
        </div>
      </div>
    </Content>
  </>;
}

export default PropertyForm