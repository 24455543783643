import { ExtendedKeycloak, formContentType } from "keycloak-lib"
import { executeInIframe } from 'utils/executeInIframe';
import axios from "axios";
import { baseUrl } from "config/constants";


const realm = process.env.REACT_APP_KEYCLOAK_REALM
const publicCliendId = process.env.REACT_APP_KEYCLOAK_CLIENT
const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL
const tenant = process.env.REACT_APP_TENANT
const reflectUrl = process.env.REACT_APP_REFLECT_URL

const createLock = () => {

  /** @type {Promise<void>} */
  let promise = Promise.resolve(), locked = false, started = false;

  /** @param {ConstructorParameters<typeof Promise>[0]} handler */
  const waitFor = (handler) => {
    if (!locked) {
      started = locked = true
      promise = new Promise(handler).finally(() => locked = false)
    }
    return promise
  }

  return { waitFor, get ready() { return started && !locked }, then(...args) { return promise.then(...args) } };
}

export const isLoggable = parsedToken => (
  parsedToken.resource_access?.[publicCliendId]?.roles?.indexOf('client') ?? -1) !== -1;

export const keycloakClient = window.keycloakClient = new ExtendedKeycloak({ clientId: publicCliendId, realm, url: keycloakUrl, isLoggable })
export const keycloak = window.keycloak = new ExtendedKeycloak({ clientId: publicCliendId, realm, url: keycloakUrl, isLoggable })

const hasUrlCallback = window.location.hash.indexOf('state=') !== -1;
const cookiesDisabled = [() => hasUrlCallback, () => !keycloakClient.silentCheckSsoRedirectUri][0]


const initKeycloakClient = () => keycloakClient.init({
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/notify-parent.html?check-sso`,
  silentCheckSsoFallback: true,
  checkLoginIframe: true,
  enableLogging: true,
}).then(err => {
  console.log('done')
}, err => {
  console.error('unable to initialize', err)
})
export const waitForInitKeycloakClient = (handler = res => res()) => keycloakClientReady.waitFor((res, rej) => {
  let resolve, p = new Promise(r => resolve = r).then(initKeycloakClient);
  // Access to initKeycloakClient should be limited to this handler lifetime
  return handler(() => { resolve(); return p; }).finally(() => resolve = null).then(res, rej)
});

// create two instance of keycloak only for original window
const isTop = !window.opener
export const keycloakClientReady = createLock();
if (isTop) waitForInitKeycloakClient();
console.log('-----')
export const keycloakReady = (
  window.location.hash.indexOf('state=') !== -1
    ? keycloakClientReady
    : Promise.resolve()
).then(() => {
  console.log('keycloak.init')
  return keycloak.init({
    checkLoginIframe: false
  })
});




/**
 * Trigger the login with a specific identity provider
 * @param {'reflect'|'facebook'|'google'} provider 
 * @param {'window'|'iframe'} mode 
 * @returns {void|Promise<string>} if mode is `iframe` returns promise to the response of the child
 */
export const startLoginWithProvider = (provider, mode = 'window') => {
  const createLoginUrl = url => keycloak.createLoginUrl({ idpHint: provider, redirectUri: url })
  if (cookiesDisabled()) {
    return keycloak.login({ idpHint: provider, redirectUri: `${window.location}?sync-auth` })
  }
  if (mode === 'window') {
    window.open(createLoginUrl(`${window.location.origin}/auth`), 'auth', 'toolbar=no,width=600,height=600');
  } else {
    return executeInIframe('signin', createLoginUrl, `${window.location.origin}/auth`);
  }
};

/**
 * Logout from keycloak client, after the operation:
 * * keycloak account should be disconnected
 * * `keycloakClient.authenticated` should be `false`
 */
export const keycloakLogout = async () => {
  if (keycloakClientReady.ready && !keycloakClient.authenticated) return;
  await executeInIframe('logout', url => keycloak.createLogoutUrl({ redirectUri: url }), undefined, cookiesDisabled() ? 'popup' : 'iframe')
  keycloakClient.setToken(null, null, 0)
}

export const deleteAccount = (token) => axios.delete(`${baseUrl}/me`, keycloak.getOptions(token))


/**
 * Ensures that keycloak and keycloadClient references the same user
 * Should be invoked before any call to keycloadClient
 */
export const ensureKeycloakSync = async () => {
  const sub = keycloak.tokenParsed?.sub
  if (!sub) throw new Error('Unauthorized')
  await keycloakClientReady
  if (sub === keycloakClient.tokenParsed?.sub) return
  await Promise.all([keycloakLogout(), connect(keycloak.token)])
  await startLoginWithProvider('reflect', 'iframe')
  return watchLogin()
  // await promise
}



/**
 * Watch keycloak client log in
 */
export const watchLogin = () => {
  return waitForInitKeycloakClient(async (initialize) => {
    await initialize()
    const parsed = keycloakClient.tokenParsed
    if (!parsed) return;
    if (!isLoggable(parsed)) {
      axios.delete(`${baseUrl}/me`, keycloakClient.getOptions())
      throw new Error('Not loggable')
    }
  })
}




const tenantHeader = { 'X-Tenant': tenant }

export const connectFirstLogin = async code => {
  return (await axios.post(`${baseUrl}/first-login?realm=${realm}`, code, {
    headers: tenantHeader,
  })).data.token;
}


export const connect = window.connect = token => {
  // set the token in the reflect idp cookies
  return executeInIframe(
    'connect',
    url => `${reflectUrl}/connect?client_id=${realm}&token=${token}&redirect_uri=${url}`,
    undefined,
    cookiesDisabled() ? 'popup' : 'iframe'
  )
}

export const updatePassword = async ({ current_password, password, }) => {
  const params = new URLSearchParams()
  params.append('password', password);
  if (current_password !== undefined) params.append('current_password', current_password);
  const headers = { ...keycloak.getHeaders(), ...formContentType, ...tenantHeader };
  await axios.post(`${baseUrl}/update-password?realm=${realm}&client_id=${publicCliendId}`, params, { headers });
}

export const unlinkProvider = async (provider) => {
  await axios.delete(`${baseUrl}/me/linked-accounts/${provider}`, {
    headers: { ...keycloak.getHeaders(), ...tenantHeader }
  });
}




