import React from 'react';
import PropTypes from 'prop-types';
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Checkbox from 'antd/lib/checkbox';
import Typography from 'antd/lib/typography';

import LocationSearchInput from 'components/location-search-input';

import './styles.scss';

const CustomInput = (props) => {
  const { type, onChange, label, value, name, options, isWithOptGroup, commentUnderInput, readOnly } = props;

  //#region Render helpers
  const renderPlacesAutoComplete = () => (
    <LocationSearchInput onChange={onChange} address={value} readOnly={readOnly} />
  )

  const renderCheckBoxes = () => (
    <div className="options">
      {
        options.map((option, index) => (
          <div key={index} className='option_container'>
            <label>{option}</label>
            <Checkbox disabled={readOnly} onChange={onChange} name={option} checked={value === option} />
          </div>
        ))
      }
    </div>
  )

  const renderSelector = () => {
    const { Option, OptGroup } = Select;
    const renderSelectorOptions = (options) => {
      return (
        options.map((option, index) => (
          <Option key={index} value={option.value}>{option.label}</Option>
        ))
      );
    }

    return (
      <Select disabled={readOnly} value={value} className='selector' onChange={onChange}>
        {isWithOptGroup ?
          options.map((optGroup, index) => (
            <OptGroup key={index} label={optGroup.label}>
              {renderSelectorOptions(optGroup.options)}
            </OptGroup>
          ))
          : renderSelectorOptions(options)}
      </Select>
    )
  };

  const renderInputNumber = () => (
    <InputNumber
      readOnly={readOnly}
      className='input_number'
      placeholder={label}
      name={name}
      value={value}
      onChange={onChange}
    />
  );

  const renderInput = () => (
    <Input
      readOnly={readOnly}
      placeholder={label}
      name={name}
      value={value}
      onChange={onChange}
    />
  )
  //#endregion Render helpers

  const { Text } = Typography;
  return (
    <div className="custom_input">
      <label>{label}</label>

      {type === 'places-autocomplete' && renderPlacesAutoComplete()}
      {type === 'checkboxes' && renderCheckBoxes()}
      {type === 'selector' && renderSelector()}
      {type === 'number' && renderInputNumber()}
      {type === 'text' && renderInput()}

      {commentUnderInput && <Text className='comment' type='success'>{commentUnderInput}</Text>}

    </div>
  )
}

CustomInput.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.array
  ]),
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  options: PropTypes.array,
  isWithOptGroup: PropTypes.bool,
  commentUnderInput: PropTypes.string,
  readOnly: PropTypes.bool,
}

export default React.memo(CustomInput);
