import Spin from 'antd/lib/spin';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import withMenu from 'hoc/with-menu';
import Content from 'pages/container/content';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { linkWith, setCredentialsPending, signout, userUpdatePassword } from 'store/user/actions';
import './styles.scss';

const Signup = () => {
  //#region hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [passwordForm] = Form.useForm();
  const pending = useSelector(state => state.user.credentialsPending)
  //#endregion


  //#region handlers
  const onFinish = (values) => {
    dispatch(setCredentialsPending(true))
    dispatch(userUpdatePassword({ password: values.newPassword }));
  };
  const linkWithGoogle = () => {
    dispatch(setCredentialsPending(true))
    dispatch(linkWith('google'));
  }
  const linkWithFacebook = () => {
    dispatch(setCredentialsPending(true))
    dispatch(linkWith('facebook'))
  };

  const passwordValidator = async (rule, value) => {
    if (value !== passwordForm.getFieldValue('newPassword')) throw new Error('equals');
  };

  const newPasswordChanged = () => {
    if (passwordForm.getFieldValue('retypePassword')) passwordForm.validateFields(['retypePassword']);
  };
  const logout = () => {
    localStorage.removeItem('kc-code')
    dispatch(signout());
  }
  //#endregion


  return <Content className="login"><div>

    <div className="sn-btn">
      <button className="flat-btn btn-google" onClick={linkWithGoogle}><i className="fab fa-google"></i>{t('singupWith')} Google</button>
    </div>
    <div className="sn-btn">
      <button className="flat-btn btn-facebook" onClick={linkWithFacebook}><i className="fab fa-facebook"></i>{t('singupWith')} Facebook</button>
    </div>

    <Form
      name="basic"
      initialValues={{}}
      onFinish={onFinish}
      form={passwordForm}
    >
      <label>{t('userInfo.newPassword')}</label>
      <Form.Item
        name="newPassword"
        rules={[{ required: true, min: 6, message: t('userInfo.newPasswordMsg') }]}
      ><Input.Password placeholder="••••••" onChange={newPasswordChanged} /></Form.Item>

      <label>{t('userInfo.retypePassword')}</label>
      <Form.Item
        name="retypePassword"
        rules={[{ required: true, validator: passwordValidator, message: t('userInfo.retypePasswordMsg') }]}
      ><Input.Password placeholder="••••••" /></Form.Item>

      <Form.Item>
        <div className="center">
          {pending ? <Spin /> : <button className="blue-btn" type="submit">{t('submit')}</button>}
        </div>
        <div className="center" style={{ marginTop: 20 }}>
          <button className="blue-btn" type="button" onClick={logout}>{t('cancel')}</button>
        </div>
      </Form.Item>
    </Form>

  </div></Content>;
}

export default withMenu(Signup, 'register');