import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import withMenu from 'hoc/with-menu';
import { keycloakClient } from 'keycloak-init';
import Content from 'pages/container/content';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { firstSignin, setFirstLoginPending, signin, signInWithProvider } from 'store/user/actions';
import './styles.scss';

const Login = () => {
  //#region hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(state => state.user.loading)
  const pending = useSelector(state => state.user.firstLoginPending)
  useEffect(() => {
    let code = new URLSearchParams(history.location.search).get('code'), stored = false;
    if (!code) stored = code = localStorage.getItem('kc-code')
    if (!code) {
      dispatch(setFirstLoginPending(false))
      localStorage.removeItem('kc-code')
      return
    }
    localStorage.setItem('kc-code', code)
    dispatch(firstSignin(code, stored && !keycloakClient.authenticated ?
      res => Modal.info({ content: t('credentials.continue'), afterClose: () => res() }) : null))
    history.replace(history.location.pathname, history.location.state);
  }, [history, dispatch, t]);
  //#endregion

  //#region handlers
  const onFinish = (values) => {
    dispatch(signin(values));
  };
  const loginWithGoogle = () => {
    dispatch(signInWithProvider('google'));
  };
  const loginWithFacebook = () => {
    dispatch(signInWithProvider('facebook'));
  };

  //#endregion

  return <Content className="login"><div>{pending ? <Spin /> : <>

    <div className="sn-btn">
      <button className="flat-btn btn-google" onClick={loginWithGoogle}><i className="fab fa-google"></i>{t('loginWith')} Google</button>
    </div>
    <div className="sn-btn">
      <button className="flat-btn btn-facebook" onClick={loginWithFacebook}><i className="fab fa-facebook"></i>{t('loginWith')} Facebook</button>
    </div>

    <Form
      name="basic"
      initialValues={{}}
      onFinish={onFinish}
    >
      <label className="center">{t('email.label')}</label>
      <Form.Item
        name="email"
        rules={[{ required: true, message: t('email.required') }]}
      >
        <Input type="email" />
      </Form.Item>

      <label className="center">{t('password.label')}</label>
      <Form.Item
        name="password"
        rules={[{ required: true, message: t('password.required') }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <div className="center">
          {loading ? <Spin /> : <button className="blue-btn" type="submit">{t('submit')}</button>}
        </div>
      </Form.Item>
    </Form></>}
  </div></Content>;
};
export default withMenu(Login, 'login');