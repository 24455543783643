import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import paymentsReducer from './payments/reducer';
import propertiesReducer from './properties/reducer';
import userReducer from './user/reducer';


const reducer = combineReducers({
  user: userReducer,
  properties: propertiesReducer,
  payments: paymentsReducer,
});

let composeEnhancers = compose;
composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?? composeEnhancers;
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export default store;