export const statusMap = {
  unprocessed: 'waiting',
  paid: 'done',
  refunded: 'done',
  canceled: 'canceled',
  failed: 'canceled',
  partially_refunded: 'inProgress',
  pending: 'inProgress',
  disputed: 'inProgress',
}
