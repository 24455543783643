import {
  SET_PAYMENTS
} from './actionTypes';

import initialState from './initialState';

/**  @returns {typeof initialState} */
const reducer = (state = initialState, action) => {
  return action.type === SET_PAYMENTS ? action.payload : state;
}

export default reducer;