/**
 * @typedef {import("keycloak-js").KeycloakTokenParsed} Token 
 */

/**
 * @typedef UserProfile
 * @property {string} firstName
 * @property {string} lastName
 */

/**
 * @typedef UserState
 * @property {boolean} loading the login form is waiting for a response
 * @property {Token|null} [token] the current token used for api requests
 * @property {UserProfile} user the user profile
 * @property {boolean} firstLoginPending user is getting his first login token
 * @property {boolean} needCredentials user state should be undefined until credentials are retrieved
 * @property {boolean} credentialsPending user credentials are loading
 */

/**
 * @type {UserState}
 */
export const userInitialState = {
  loading: false,
  token: undefined,
  user: null,
  firstLoginPending: false,
  needCredentials: false,
  credentialsPending: false,
}
