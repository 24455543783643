import { gql } from '@apollo/client'
import fragments from 'fragments';

export const CREATE_ADDRESS = gql`
  mutation CreateAddress(
    $street_name: String!,
    $country: String!,
    $city: String!,
    $postal_code: String!,
    $civic_number: String!,
    $latitude: Float,
    $longitude: Float,
    $nickname: String,
    $province: String!,
    $street_type: String!,
  ) {
    createAddress(input: {
      street_name: $street_name,
      country: $country,
      city: $city,
      postal_code: $postal_code,
      civic_number: $civic_number,
      latitude: $latitude,
      province: $province,
      longitude: $longitude,
      nickname: $nickname,
      street_type: $street_type,
    }){id, ...AddressFragment}
  }
  ${fragments.address}
`;